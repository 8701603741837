// JavaScript source code
import React, { useState, useEffect } from 'react';
import { useSpring } from 'react-spring';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { checkUserData } from '../utils/session';
import Prefcard from './Prefcard';
import styles from './Scrollcards.module.css'; // Import the styles module

const Scrollcards = () => {

    const { userData } = useAuth(); // Use the userData state from AuthProvider
    const navigate = useNavigate();

    const fadeIn = useSpring({
        opacity: window.scrollY > 200 ? 1 : 0, // Adjust the scroll threshold as needed
        from: { opacity: 0 },
    });

    useEffect(() => {
        const handleScroll = () => {
            // Update the fade-in effect based on the scroll position
            fadeIn.opacity.set(window.scrollY > 0 ? 1 : 0);
        };

        // Attach the event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Check user data and navigate if needed
        if (checkUserData(userData)) {
            // session expired go to home page
            alert('Session Expired! Login Again.');
            navigate('/');
        }

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, [fadeIn.opacity, userData, navigate]); 

    const [cardsData, setCardsData] = useState([]);

    useEffect(() => {
        // Fetch data from your backend API
        const getprefcards = async () => {

            const generateRandomColor = () => {
                const letters = '0123456789ABCDEF';
                let color = '#';
                for (let i = 0; i < 6; i++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
            };

            const generateRandomColorsArray = (count) => {
                const colors = [];
                for (let i = 0; i < count; i++) {
                    colors.push(generateRandomColor());
                }
                return colors;
            };

            // Example: Generate an array of 5 random colors
            const colorArray = generateRandomColorsArray(5);

          
            // Fetch the stored data from local storage
            const fetchedJsonString = localStorage.getItem('moneyshape');

            // Parse the JSON string back into a JavaScript object
            const fetchedData = JSON.parse(fetchedJsonString);
            const cardText = fetchedData.cardtexts
            const cardArray = cardText.map((text, index) => ({
                description: text,
                title: fetchedData.labels[index],
                score: fetchedData.weights1[index],
                color: colorArray[index]
            }));
            setCardsData(cardArray);
                        
        };
        // Check if selectedCard or otherCard has changed before calling submitanswer
        
            getprefcards();
        
    }, [navigate]);

    

    return (
        
        <div className={styles.sliderCards}>
            {cardsData.map((card, index) => (
                <Prefcard
                    key={index}
                    title={card.title}
                    score={card.score}
                    description={card.description}
                    sliderValue={card.score}
                    borderColor={card.color }
                    
                />
            ))}
        </div>
      
    );
};

export default Scrollcards;
