// HeroSection.jsx

import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { useNavigate,Link } from 'react-router-dom';
import styles from './Hero.module.css'; // Import the CSS file
import Button from './Button'; // Import the Button component
import ButtonSec from './Button_sec'; // Import the Button component

const HeroSection = ({ onButtonClick, setShowGetStarted }) => {
    const fadeIn = useSpring({ opacity: 1, from: { opacity: 0 } });

    const words = ['Magic Number!', 'Savings rate!', 'Lifestyle growth!', 'Property!', 'Target age!', 'Expenses!', 'Allocations!','Withdrawal!', 'Returns!', 'Responsibilities!'];
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    
    const navigate = useNavigate();
    const [buttonVisible, setButtonVisible] = useState(true);

  const handleButtonClick = () => {
    // Handle button click logic
      setShowGetStarted(false);
    
   
  };
    const handleTryNowClick = () => {
        // Handle button click logic
        onButtonClick(navigate);
        setShowGetStarted(true);
        // Hide the button
        setButtonVisible(false);
    };
    useEffect(() => {
        const updateWordIndex = () => {
            setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        };

        const intervalId = setInterval(updateWordIndex, 2000);

        return () => clearInterval(intervalId);
    }, [words.length]); // Include words.length in the dependency array

    return (
        <animated.section className={styles['hero-section']} style={fadeIn}>
            <div className={styles['hero-content']}>
                <h1 className={styles['custom-h1']}>
                    <div className={styles['calling-all']}>Wish to FIRE? </div>
                    <div className={styles['changing-word']}>  {words[currentWordIndex]}</div>
                </h1>
                <p className={styles['custom-p']}>
                    Here you can <span className={styles['emphasis']}>optimise</span> all these variables.
                </p>
                
            </div>
            {buttonVisible && (
        <div className={styles.connectButton}>
                     <Link to="/signup">
                        <Button label='Sign Up >>' onClick={handleButtonClick}></Button>

                </Link>

                    <ButtonSec label='Trial' onClick={handleTryNowClick}></ButtonSec>

        </div>
       
      )}
      {buttonVisible && (
           <div className={styles.purpose}>
       <div className={styles['purpose-p']}> No spam | No ads | No selling data🔒</div>
        </div>
      )}
        </animated.section>
    );
};

export default HeroSection;
