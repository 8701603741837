

import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa'; // Importing the delete icon from the react-icons library
import styles from './Flow.module.css'; // Import the CSS module
import Button from './Button'; // Import the Button component
import { apiDomain } from './Domain'; // Import the apiDomain variable
import { useAuth } from '../AuthContext';
import {checkUserData} from '../utils/session';
import { logout } from '../utils/utils';

const Flows = () => {
    
    const { userData } = useAuth(); // Use the userData state from AuthProvider
     const navigate = useNavigate();

    const fadeIn = useSpring({
    opacity: window.scrollY > 200 ? 1 : 0, // Adjust the scroll threshold as needed
    from: { opacity: 0 },
  });

  const [notification, setNotification] = useState('');
    const [loading, setLoading] = useState(false);
   
  const handleButtonClick = async () => {

      // Reset previous notifications
    setNotification('');
     setLoading(true);
    // Validate that all fields are filled
      if ((!selfsalary && !spousesalary) || !essentials) {
      setNotification('All fields are required');
      // Automatically clear the notification after 3 seconds (3000 milliseconds)
      setTimeout(() => {
        setNotification('');
        setLoading(false);
      },  1000);
      return;
    }

 


  // Gather data from state variables
  const data = {
   selfsalary,
    spousesalary,
    
    essentials,
   
  };
  // Filter out fields with empty strings
const filteredData = Object.fromEntries(
  Object.entries(data).filter(([_, value]) => value !== "")
);

      // Send data to Flask backend using Axios
      try {
      const apiUrl = `${apiDomain}/api/submit-flows-data`;
      const response = await axios.post(apiUrl, filteredData, {
      method: 'POST',
      mode: 'cors',
      withCredentials: true, // Include credentials (cookies) with the request
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-App-Identifier': 'wishh/1.0',
        // Other headers as needed
      },
    });
    
    const fetch_data = response.data;
    if (fetch_data.error) {

       alert("Please try again!");

        await logout(navigate);
    }

   else {
      // Handle the response from the backend
        console.log('API Response Message:', response.data.message);
       alert(response.data.message)
     }   
    }
    catch(error) {
      console.error('Error posting data:', error.message);
      // Handle errors or provide user-friendly messages as needed
    }
    finally {
        // Reset loading state after the request is complete
        setLoading(false);
      }
};




useEffect(() => {
  const handleScroll = () => {
    // Update the fade-in effect based on the scroll position
    fadeIn.opacity.set(window.scrollY > 0 ? 1 : 0);
  };

  // Attach the event listener when the component mounts
  window.addEventListener('scroll', handleScroll);

  // Check user data and navigate if needed
  if (checkUserData(userData)) {
    // session expired go to home page
    alert('Session Expired! Login Again.');
    navigate('/');
  }

  // Clean up the event listener when the component unmounts
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };

}, [fadeIn.opacity, userData, navigate]); 

    
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make a GET request to fetch data from the Flask backend
        const apiUrl = `${apiDomain}/api/fetch-flows-data`;
        const response = await axios.get(apiUrl, {
          
          method: 'GET',
          mode: 'cors',
          withCredentials: true, // Include credentials (cookies) with the request
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-App-Identifier': 'wishh/1.0',
            // Other headers as needed
        }
        });
        const fetch_data = response.data
        if (fetch_data.code === 401) {
        
            
        alert("Session timed out. Please login again.");

            await logout(navigate);
        
        } else if (fetch_data.error) {

            alert('Something went wrong.  Please login again.');
           // console.log(fetch_data)
            await logout();
        }
        else {
            // Check if data is available in the response
        const responseData = response.data.data;
        if (responseData) {
          // Update state variables with fetched data
        
        setSelfsalary(responseData.selfsalary || '');
         setSpousesalary(responseData.spousesalary || '');
        
        setEssentials(responseData.essentials || '');
       
          
        }
          // Optionally, you can also trigger any necessary actions or updates here
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
        // Handle errors or provide user-friendly messages as needed
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, [navigate]); // The empty dependency array ensures that this effect runs only once on mount


  
     const [selfsalary, setSelfsalary] = useState('');
     const [spousesalary, setSpousesalary] = useState('');
   const [inflow, setInflow] = useState('');
   const [inflowname, setInflowName] = useState('');
   const [inflowfrequency, setInflowFrequency] = useState('');
   const [inflowtill, setInflowTill] = useState('');
  const [essentials, setEssentials] = useState('');
  

    
         const [activeTab, setActiveTab] = useState('salary'); // Default to 'self' tab   
    
   

  return (
     <animated.section className={styles['profile-section']} >
      <div className={styles.container}>
      <h2 className={styles.heading}>Household Flows</h2>
      <div className={styles.customp}>
                    Enter details as a couple, if married
              </div>
         {/* Tab Navigation */}
            <div className={styles.tabContainer}>
              <div
                className={`${styles.tab} ${activeTab === 'salary' && styles.activeTab}`}
                onClick={() => setActiveTab('salary')}
              >
                Salary
              </div>
              <div
                className={`${styles.tab} ${activeTab === 'outflows' && styles.activeTab}`}
                onClick={() => setActiveTab('outflows')}
              >
                Outflows
              </div>
               <div
                className={`${styles.tab} ${activeTab === 'inflows' && styles.activeTab}`}
                onClick={() => alert('Coming Soon')}// setActiveTab('inflows')}
              >
                Inflows
              </div>
              {/* Add more tabs as needed for other roles */}
            </div>

      <div className={styles.cardContainer}>
    <div className={`${styles.card} ${activeTab === 'salary' && styles.activeCard}`}>

       <div className={styles.inputContainer}>
        <div className={styles.labelRow}>
          <label className={styles.label} htmlFor="salaryInput"> Self, post tax: <span className={styles.customp}>
                    (p.a.)
                </span></label>
          
                              {selfsalary !== "" && (
                                  <span className={styles.value}>&#x20B9; {selfsalary.toLocaleString()}
                                      <FaTrash className={styles.deleteIcon} onClick={() => setSelfsalary('')} />
                                  </span>
                              )}
        </div>
        <input
        id="salaryInput"
          type="range"
          min="100000"
          max="10000000"
          step="10000"
          value={selfsalary}
          onChange={(e) => setSelfsalary(parseInt(e.target.value))}
          className={styles.inputRange}
        />
      </div>
       <div className={styles.inputContainer}>
        <div className={styles.labelRow}>
          <label className={styles.label} htmlFor="spousesalaryInput"> Spouse, post tax: <span className={styles.customp}>
                    (p.a.)
                </span></label>
                              {spousesalary !== "" && (
                              <span className={styles.value}>&#x20B9; {spousesalary.toLocaleString()}
                                  <FaTrash className={styles.deleteIcon} onClick={() => setSpousesalary('')} />
                                  </span>
                              )}
        </div>
        <input
        id="spousesalaryInput"
          type="range"
          min="100000"
          max="10000000"
          step="10000"
          value={spousesalary}
          onChange={(e) => setSpousesalary(parseInt(e.target.value))}
          className={styles.inputRange}
        />
      </div>
      </div>
 <div className={`${styles.card} ${activeTab === 'inflows' && styles.activeCard}`}>
     
  <div className={styles.inputContainer}>
  

  <div className={styles.labelRow}>
    <label className={styles.label} htmlFor="inflowname">Name:</label>
    </div>
    <input
    id="inflowname"
      type="text"
      value={inflowname}
      onChange={(e) => setInflowName(e.target.value)}
      className={styles.inputText}
    />
  

  <div className={styles.labelRow}>
    <label className={styles.label} htmlFor="inflowfrequency">Frequency:</label>
    </div>
    <select
    id="inflowfrequency"
      value={inflowfrequency}
      onChange={(e) => setInflowFrequency(e.target.value)}
      className={styles.inputSelect}
    >
      <option value="monthly">Monthly</option>
      <option value="annually">Annually</option>
      {/* Add other frequency options as needed */}
    </select>
  

  <div className={styles.labelRow}>
    <label className={styles.label} htmlFor="inflowuntill">Till:</label>
    </div>
    <input
    id="inflowuntill"
      type="date"
      value={inflowtill}
      onChange={(e) => setInflowTill(e.target.value)}
      className={styles.dateInput}
    />
 

  <div className={styles.labelRow}>
    <label className={styles.label} htmlFor="inflowvalue">Value:</label>
     <span className={styles.value}>&#x20B9; {inflow.toLocaleString()}</span>
     </div>
    <input
    id="inflowvalue"
      type="range"
      min="100000"
      max="10000000"
      step="10000"
      value={inflow}
      onChange={(e) => setInflow(parseInt(e.target.value))}
      className={styles.inputRange}
    />
  
</div>

     
       
     
      </div>
       <div className={`${styles.card} ${activeTab === 'outflows' && styles.activeCard}`}>

       

     

      <div className={styles.inputContainer}>
      <div className={styles.labelRow}>
        <label className={styles.label} htmlFor="expenses">Living expenses: <span className={styles.customp}>
                    (p.m.)
                              </span></label>
                              {essentials !== "" && (
                                  <span className={styles.value}>&#x20B9; {essentials.toLocaleString()}
                                      <FaTrash className={styles.deleteIcon} onClick={() => setEssentials('')} />
                                  </span>
                              )}
        </div>
        <input
        id="expenses"
          type="range"
          min="0"
          max="500000"
          step="10000"
          value={essentials}
          onChange={(e) => setEssentials(parseInt(e.target.value))}
          className={styles.inputRange}
        />
      </div>
       
     
      </div>
        </div>
      <div className={styles.connectButton}>
          <Button label='Submit' onClick={handleButtonClick} disabled={loading}></Button>

              </div>
              {notification && <p style={{ color: 'yellow',fontSize: '10px'  }}>{notification}</p>}
    </div>
        
     </animated.section>
  );
};

export default Flows;
