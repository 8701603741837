import React, { createContext, useContext, useState } from 'react';



const AuthContext = createContext();



export const AuthProvider = ({ children }) => {

const [userData, setUserData] = useState({});


    return (
        <AuthContext.Provider value={{ userData,setUserData }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};