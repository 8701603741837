// JavaScript source code

// Card.js

import React from 'react';
import styles from './Prefcard.module.css'; // Import the styles module

const Prefcard = ({ title, score, description, sliderValue, borderColor }) => {

    const cardStyles = {
        borderColor: borderColor,
    };

    return (
        <div className={styles.card} style={cardStyles}>
            <h3 className={styles.heading}>{title}</h3>
            <p className={styles.paragraph}>Score: {score}/10</p>
            <input
                type="range"
                min="0"
                max="10"
                value={sliderValue}
                readOnly
                className={styles.slider}
            />
            <p className={styles.paragraph}>{description}</p>
        </div>
    );
};

export default Prefcard;

