
import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import axios from 'axios';
import { useNavigate  } from 'react-router-dom';
import styles from './Wishhes.module.css'; // Import the CSS module
import Button from './Button'; // Import the Button component
import { apiDomain } from './Domain'; // Import the apiDomain variable
import { useAuth } from '../AuthContext';
import {checkUserData} from '../utils/session';
import { logout } from '../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const Wishhes = ({ onButtonClick }) => {
    
    const { userData } = useAuth(); // Use the userData state from AuthProvider
     const navigate = useNavigate();

    const fadeIn = useSpring({
    opacity: window.scrollY > 200 ? 1 : 0, // Adjust the scroll threshold as needed
    from: { opacity: 0 },
  });

  const [notification, setNotification] = useState('');
    const [loading, setLoading] = useState(false);
   
  const handleButtonClick = async () => {

      // Reset previous notifications
    setNotification('');
     setLoading(true);
    // Validate that all fields are filled
      if (!RE || !leanfat || !returns) {
          //console.log(returns)
      setNotification('All fields are required');
      // Automatically clear the notification after 3 seconds (3000 milliseconds)
      setTimeout(() => {
        setNotification('');
        setLoading(false);
      },  2000);
      return;
    }

 


  // Gather data from state variables
  const data = {
    
    RE,
      leanfat,

    returns,
  };
 
      // Send data to Flask backend using Axios
      try {
      const apiUrl = `${apiDomain}/api/submit-wishhes-data`;
      const response = await axios.post(apiUrl, data, {
      method: 'POST',
      mode: 'cors',
      withCredentials: true, // Include credentials (cookies) with the request
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-App-Identifier': 'wishh/1.0',
        // Other headers as needed
      },
    });
   
    const fetch_data = response.data;
    if (fetch_data.error) {

      alert("Please try again!");

            await logout(navigate);
    } else if (fetch_data.code === 201) {

        alert("Complete Profile, Flows & Networth!")

    }

   else {
      // Handle the response from the backend
        console.log('API Response Message:', response.data.message);
        // You can also update the state or perform other actions based on the response
        onButtonClick(); // Call the onButtonClick prop to link to GldPlotly display
     }   
    }
    catch(error) {
      console.error('Error posting data:', error.message);
      // Handle errors or provide user-friendly messages as needed
    }
    finally {
        // Reset loading state after the request is complete
        setLoading(false);
      }
};




useEffect(() => {
  const handleScroll = () => {
    // Update the fade-in effect based on the scroll position
    fadeIn.opacity.set(window.scrollY > 0 ? 1 : 0);
  };

  // Attach the event listener when the component mounts
  window.addEventListener('scroll', handleScroll);

  // Check user data and navigate if needed
  if (checkUserData(userData)) {
    // session expired go to home page
    alert('Session Expired! Login Again.');
    navigate('/');
  }

  // Clean up the event listener when the component unmounts
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };

}, [fadeIn.opacity, userData, navigate]); 

    
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make a GET request to fetch data from the Flask backend
        const apiUrl = `${apiDomain}/api/fetch-wishhes-data`;
        const response = await axios.get(apiUrl, {
          
          method: 'POST',
          mode: 'cors',
          withCredentials: true, // Include credentials (cookies) with the request
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-App-Identifier': 'wishh/1.0',
            // Other headers as needed
        }
        });
        const fetch_data = response.data
        if (fetch_data.error) {
        
            
        alert("Please try again!");

         await logout(navigate);
        
        }
        else {
            // Check if data is available in the response
        const responseData = response.data.data;
        if (responseData) {
          // Update state variables with fetched data
            setCurrentYear(new Date().getFullYear());
        setRE(responseData.RE || '');
        setleanfat(responseData.leanfat || 1.0);
        setReturns(responseData.returns || '');
          
        }
          // Optionally, you can also trigger any necessary actions or updates here
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
        // Handle errors or provide user-friendly messages as needed
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, [navigate]); // The empty dependency array ensures that this effect runs only once on mount


    const [currentYear, setCurrentYear] = useState('');
    
    const [RE, setRE] = useState('');
  const [leanfat, setleanfat] = useState(1.0);
  const [returns, setReturns] = useState(0.08);
    const [property, setProperty] = useState('');
    
          
    
     const [activeTab, setActiveTab] = useState('FIRE'); // Default to 'self' tab    

  return (
     <animated.section className={styles['profile-section']} >
      <div className={styles.container}>
      <h2 className={styles.heading}>Configure wishhes!</h2>
      <div className={styles.customp}>
                    Play around to see what you like..
              </div>

 {/* Tab Navigation */}
            <div className={styles.tabContainer}>
              <div
                className={`${styles.tab} ${activeTab === 'FIRE' && styles.activeTab}`}
                onClick={() => setActiveTab('FIRE')}
              >
                FIRE
              </div>
               <div
                className={`${styles.tab} ${activeTab === 'spending' && styles.activeTab}`}
                onClick={() => setActiveTab('spending')}
              >
                Spending
              </div>
               <div
                className={`${styles.tab} ${activeTab === 'risk' && styles.activeTab}`}
                onClick={() => setActiveTab('risk')}
              >
                Risk
                  </div>
                  <div
                      className={`${styles.tab} ${activeTab === 'property' && styles.activeTab}`}
                      onClick={() => setActiveTab('property')}
                  >
                      Property
                  </div>
              
              <div
                className={`${styles.tab} ${activeTab === 'goals' && styles.activeTab}`}
                onClick={() => alert('Coming Soon')}
              >
                Goals
              </div>
              
              {/* Add more tabs as needed for other roles */}
            </div>

      <div className={`${styles.card} ${activeTab === 'FIRE' && styles.activeCard}`}>
      <div className={styles.inputContainer}>
      <div className={styles.labelRow}>
        <label className={styles.label}>FIRE year: </label>
                      <span className={styles.value}>{(currentYear + RE).toLocaleString()}</span>
        </div>
        <input
          type="range"
          min="1"
          max="70"
          step="1"
                      value={RE}
                      onChange={(e) => {
                          setRE(parseInt(e.target.value));
                          setCurrentYear(new Date().getFullYear());
                      }}
          className={styles.inputRange}
        />
      </div>
              </div>

              <div className={`${styles.card} ${activeTab === 'spending' && styles.activeCard}`}>
      <div className={styles.inputContainer}>
      <div className={styles.labelRow}>
        <label className={styles.label}>Lean Vs FAT: <span className={styles.customp}>
                    
                </span></label>
                      <span className={styles.value}>{(leanfat * 100).toLocaleString()}%</span>
        </div>
        <input
          type="range"
          min="0.5"
          max="1.5"
          step="0.1"
          value={leanfat}
          onChange={(e) => setleanfat(parseFloat(e.target.value))}
          className={styles.inputRange}
        />
      </div>
              </div>
              <div className={`${styles.card} ${activeTab === 'risk' && styles.activeCard}`}>
      <div className={styles.inputContainer}>
      <div className={styles.labelRow}>
        <label className={styles.label}>Target Returns: <span className={styles.customp}>
                    
                </span></label>
                      <span className={styles.value}>{(returns * 100).toLocaleString()}%</span>
        </div>
        <input
          type="range"
          min="0.05"
          max="0.15"
          step="0.01"
          value={returns}
          onChange={(e) => setReturns(parseFloat(e.target.value))}
          className={styles.inputRange}
        />
                  </div>
              </div>
                  <div className={`${styles.card} ${activeTab === 'property' && styles.activeCard}`}>
              <div className={styles.inputContainer}>
                  <label className={styles.singlelabel}>Property:

                      <FontAwesomeIcon icon={faLock} className={styles.lockIcon} />

                  </label>
                  <select
                      value={property}
                      onChange={(e) => setProperty(e.target.value)}
                      className={styles.inputSelect}
                      disabled
                  >
                      {/*<option value="">Select</option>*/}
                      {/*<option value="yes">Have property</option>*/}
                      <option value="no">Want to purchase</option>

                  </select>
              </div>
              </div>
      <div className={styles.connectButton}>
          <Button label='Submit' onClick={handleButtonClick} disabled={loading}></Button>

              </div>
              {notification && <p style={{ color: 'black' }}>{notification}</p>}
    </div>
          
     </animated.section>
  );
};

export default Wishhes;
