// Button.js

import React, { useState } from 'react';
import styles from './Button.module.css'; // Import your CSS file for styling

const Button = ({ label, onClick, disabled }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <button
            className={`${styles.button} ${isHovered ? styles.hovered : ''} ${disabled ? styles.disabled : ''}`}
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            disabled={disabled}
        >
            {label}
        </button>
    );
};

export default Button;

