// Footer.js
// Footer.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faReddit } from '@fortawesome/free-brands-svg-icons';

import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

const Footer = () => {
    const currentYear = new Date().getFullYear();
  return (
      <div>
       <div className={styles.sectionDivider}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 5" className={styles.svgShape} >
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={{ stopColor: '#00CCFF ', stopOpacity: 1 }} />
              <stop offset="100%" style={{ stopColor: '#FF9900 ', stopOpacity: 1 }} />
              <stop offset="100%" style={{ stopColor: '#99FFCC', stopOpacity: 0.5 }} />
            </linearGradient>
          </defs>
          <path d="M0,2.5 C10,1 30,4 45,2.5 C60,1 80,4 90,2.5" stroke="url(#gradient)" strokeWidth="0.4" fill="none" />
        </svg>


      </div>
    <footer className={styles.footer}>
      <div className={styles.row}>
        <div className={styles.column}>
          <h4 className={styles.customHeading}> </h4>
          <p className={styles.customText}>dhruv@icebergtribe.com</p>
        </div>
        <div className={styles.column}>
          <h4 className={styles.customHeading}> </h4>
          <p className={styles.customText}> </p>
        </div>
        <div className={styles.column}>
          <h4 className={styles.customHeading}> </h4>
          <p className={styles.customText}> 
         <a
              href="https://www.linkedin.com/company/iceberg-tribe/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Iceberg Tribe LinkedIn Page"
            >
              <FontAwesomeIcon icon={faLinkedin} size="lg" style={{ color: 'white' }} />
            </a>
            <a
              href="https://www.reddit.com/r/FIRE_wishhin/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Wishh reddit forum"
            >
              <FontAwesomeIcon icon={faReddit} size="lg" style={{ color: 'red', background:'transparent'}} />
            </a>
          
          
          </p>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <h4 className={styles.customHeading}>© {currentYear} ICEBERG TRIBE. ALL RIGHTS RESERVED. </h4>
          <p className={styles.customText}></p>
        </div>
        <div className={styles.column}>
          <h4 className={styles.customHeading}>
          
          <Link to="/terms-privacy">TERMS & PRIVACY</Link>
          
          </h4>
          <p className={styles.customText}> </p>
        </div>
        <div className={styles.column}>
                      <h4 className={styles.customHeading}>

                          <a
                              href="https://forms.gle/dRXzoG9MimCaaeWV9"
                              target="_blank"
                              rel="noopener noreferrer"
                              
                          >FEEDBACK</a>


                      </h4>
          
                    
        </div>
      </div>
    </footer>
    </div>
  );
};

export default Footer;
