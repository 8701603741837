import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import styles from './Google.module.css'; // Your CSS module
import axios from 'axios';
import LoadingAnimation from './LoadingAnimation';
import { apiDomain } from './Domain'; // Import the apiDomain variable
import { useAuth } from '../AuthContext';
import {init} from '../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReddit } from '@fortawesome/free-brands-svg-icons';

const GoogleSignIn = () => {
    const [loading, setLoading] = useState();
   // to initialise user id in session
    const { setUserData } = useAuth();

    const authUrl = `${apiDomain}/api/googleauthurl`;
        const fetchAuthUrl = async () => {
            try {
                const response = await axios.get(authUrl, {
                    method: 'GET',
                    mode: 'cors',
                    withCredentials: true, // Use boolean value directly
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'X-App-Identifier': 'wishh/1.0',
                        // Other headers as needed
                    },
                });

                const data = response.data;

                if (data.error) {
                    console.error(`Error Code ${data.code}: ${data.message}`);
                } else {
                    // Use data directly without updating state

                    // Redirect the user to the authentication URL
                    //window.localStorage.setItem('authUrl', data.data);
                    
                     window.location.href = data.data;
                    // Consider further actions based on the data
                }
            } catch (error) {
                console.error('Error fetching configuration:', error);
                // Handle error appropriately, maybe show a user-friendly message
            }
        };

 
const handleButtonClick = async () => {
    try {
        setLoading(true);
      // Call the init function within a useEffect-like pattern
      await init(setUserData);

      // Continue with other asynchronous operations
      await fetchAuthUrl();
       
      // Other logic as needed
    } catch (error) {
      // Handle errors here (e.g., display an error message or log the error)
      console.error('Error in handleButtonClick:', error);
    }
  };
   
  
  return (
    <main>
      {/* Include the section explaining the purpose of the page */}

          {loading ? (
              <LoadingAnimation />
          ) : (
          <section className={styles.purpose}>
        <div className={styles.container}>
          <div className={`${styles['col-md-8']} ${styles['offset-md-2']} ${styles['purpose-content']}`}>
            <a href="/">
              {/* Add the anchor tag here */}
              <img
                src={process.env.PUBLIC_URL + '/logo.jpg'}
                alt="logo"
                className={styles.logo}
              />
            </a>
            {/* Close the anchor tag here */}
            <h4 className={`${styles['purpose-description']} ${styles['margin-bottom']}`}>
              Login into your account
            </h4>
             <div className={styles['purpose-text']}><FontAwesomeIcon icon={faReddit} size="lg" style={{ color: 'red' }} /> Join r/FIRE_wishhin </div>
              <button className={styles['gsi-material-button']} onClick={handleButtonClick}>
                <div className={styles['gsi-material-button-state']}></div>
                <div className={styles['gsi-material-button-content-wrapper']}>
                  <div className={styles['gsi-material-button-icon']}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ display: 'block' }}>
                                        <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                        <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                        <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                        <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                        <path fill="none" d="M0 0h48v48H0z"></path>
                                    </svg>
                  </div>
                  <span className={styles['gsi-material-button-contents']}>
                    Continue with Google
                  </span>
                  <span style={{ display: 'none' }}>Continue with Google</span>
                </div>
              </button>
            

            <div className={styles.link}>
              
                Create an account? <Link to="/signup">Sign up</Link>
              
                      </div>
                    
          </div>
        </div>
              </section>
          )}
    </main>
  );
};

export default GoogleSignIn;
