const checkUserData = (userData) => {
  // Check if userdata.code and userdata.loggedin are not set
  if (userData.code== null || userData.loggedin === null) {
    // Show alert
   

    // Return true indicating that session expired
    return true;
  }

  // Return false indicating that navigation is allowed
  return false;
};

export { checkUserData };
