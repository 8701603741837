import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css'; // Import your CSS file
import Button from './Button'; // Import the Button component


const Header = ({ showGetStarted, setShowGetStarted }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const handleScroll = () => {
        const header = document.querySelector(`.${styles.header}`);
        const scrollThreshold = 10; // Adjust the scroll threshold as needed

        if (window.scrollY > scrollThreshold) {
            header.classList.add(styles.fixed);
        } else {
            header.classList.remove(styles.fixed);
        }
    };

    // Debounce the scroll event
    const debouncedScrollHandler = debounce(handleScroll, 5); // Adjust delay as needed


    // Add the debounced scroll event listener
    React.useEffect(() => {
        const handleScrollDebounced = () => {
            debouncedScrollHandler(); // Call the debounced function
        };

        window.addEventListener('scroll', handleScrollDebounced);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScrollDebounced);
        };
    }, [debouncedScrollHandler]); // Include debouncedScrollHandler in the dependency array


    


    const handleButtonClick = async () => {
        // Handle button click logic

        setShowGetStarted(false);

       
    };

   
    return (
        
        <header className={`${styles.header} ${menuOpen ? styles.fixed : ''}`}>
        {/* Logo and brand name */}
        <div className={styles["logo-container"]}>
            <div className={styles.logo}>
                    <Link to="/" onClick={() => (window.location.href = '/')}>
                    <img src={process.env.PUBLIC_URL + '/logo.jpg'} alt="Logo" />
                </Link>
            </div>
            <span className={styles["brand-name"]}>
                    <Link to="/" onClick={() => (window.location.href = '/')}>wishh</Link>
            </span>
            </div>

            {/* Get Started Button */}
           

        <nav className={styles.nav}>
            {/*<ul>*/}
                
            {/*</ul>*/}
        </nav>
            <div className={styles.connectButton}>
                {showGetStarted && <Link to="/signup">
                    <Button label='Sign Up' onClick={handleButtonClick}></Button>
                </Link>}
            </div>
        <div className={styles['burger-menu']} onClick={toggleMenu}>
            <div className={styles.bar1}></div>
            <div className={styles.bar2}></div>
            <div className={styles.bar3}></div>
        </div>
    </header>
    );
};

export default Header;
