// Import necessary libraries
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { apiDomain } from './Domain';
import LoadingAnimation from './LoadingAnimation';
import { useAuth } from '../AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { login } from '../utils/utils';

const GoogleAuth = () => {
    const [loading, setLoading] = useState(true);
    const { userData,setUserData } = useAuth(); // Use the loggedIn state from AuthProvider
    const navigate = useNavigate();
    const location = useLocation();
    const sendAuthorizationCodeToFlask = useCallback(async (auth_code, state) => {
        const tokenUrl = `${apiDomain}/api/tokencallback`;

        try {
            // Send the authorization code to Flask backend
            const response = await axios.post(tokenUrl, {
                code: auth_code,
                state: state
            }, {
                method: 'POST',
                mode: 'cors',
                withCredentials: true, // Use boolean value directly
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-App-Identifier': 'wishh/1.0',
                },



            });
            const response_code = response.data.code
            console.log(response_code)
            if (response_code === 201 || response_code === 202) {
                // Optionally handle success

                await login(userData, setUserData);
                setLoading(false);


                navigate('/profile');

            } else if (response_code === 203) {

                await login(userData, setUserData);
                setLoading(false);

                navigate('/wishhes');
            } else if (response_code === 500) {
                setLoading(false);
                alert("Something went wrong. Please try again!")
                window.location.href = '/';
            }
        } catch (error) {
            // Handle network or other errors
        }
    }, [navigate, setUserData, userData]);

    useEffect(() => {
        // Extract authorization code from the URL after the redirect
        const urlParams = new URLSearchParams(location.search);
        const auth_code = urlParams.get('code');
        const state = urlParams.get('state');

        // Check if there is an authorization code in the URL
        if (auth_code) {
            // Send the authorization code to the Flask backend for further processing
            sendAuthorizationCodeToFlask(auth_code, state);

            // Optionally, you may want to clear the URL of the authorization code
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }, [location.search, sendAuthorizationCodeToFlask]);

    

    return (
        <div>
            {loading && <LoadingAnimation />}
        </div>
    );
};

export default GoogleAuth;
