
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Plotly from 'plotly.js/lib/core';
import Scatter from 'plotly.js/lib/scatter';
import Bar from 'plotly.js/lib/bar';
import styles from './GraphPlot.module.css'; // Import the CSS file
import LoadingAnimation from './LoadingAnimation';
import { apiDomain } from './Domain'; // Import the apiDomain variable


// Register the modules
Plotly.register([Scatter, Bar]);

// Define config as a memoized constant
const config = {
    responsive: true,
    displayModeBar: false,
    staticPlot: false,
};

// Define graphLayout as a memoized constant
//const graphLayout = {
//    title: {
//        text: '',
//        font: {
//            size: 12,
//            color: '#666'
//        }
//    },
//    xaxis: {
//        title: 'Age',
//        titlefont: {
//            size: 10,
//            color: '#666'
//        },
//        tickfont: {
//            size: 10,
//            color: '#666'
//        }
//    },
//    yaxis: {
//        title: '',
//        titlefont: {
//            size: 10,
//            color: '#666'
//        },
//        tickfont: {
//            size: 10,
//            color: '#666'
//        },
//        showgrid: false,
//        tickformat: 's',
//        hoverformat: ',.0f',
//        tickprefix: '₹',
//        exponentformat: 'none'
//    },

//    legend: {
//        orientation: 'h', // 'h' for horizontal, 'v' for vertical
//        y: 1.1, // Adjust this value to position the legend
//        x: 0.5,
//        xanchor: 'center',
//        yanchor: 'top',
//        font: {
//            size: 8, // Adjust this value for the desired font size
//        },
//    },

//    margin: {
//        l: 60,
//        r: 20,
//        t: 0,
//        b: 30
//    },


//    plot_bgcolor: '#040C21',
//    paper_bgcolor: '#040C21'
//};

const GldPlotly = () => {
    const [gldgraphData, setGLDgraphData] = useState(null);
    const [gldgraphlayout, setGLDgraphlayout] = useState(null);
    const [ratiographData, setRatiographData] = useState(null);
    const [ratiographlayout, setRatiographlayout] = useState(null);
    const [expgraphData, setEXPgraphData] = useState(null);
    const [expgraphlayout, setEXPgraphlayout] = useState(null);
    const [scapgraphData, setScapgraphData] = useState(null);
    const [scapgraphlayout, setScapgraphlayout] = useState(null);

    const [loading, setLoading] = useState(true);
    const [datafetched, setDatafetched] = useState(false);
    
    useEffect(() => {

    // useCallback for the fetchData function
        const fetchData = async (datafetched) => {

            if (!datafetched) {

                try {
                    setLoading(true);
                    //console.log('fetch');
                    const apiUrl = `${apiDomain}/api/fetchGraphs`;

                    const response = await axios.get(apiUrl, {
                        method: 'GET',
                        mode: 'cors',
                        withCredentials: true,
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'X-App-Identifier': 'wishh/1.0',
                        },
                        responseType: 'json', // Set responseType to 'json'
                    });

                    if (response.data.code === 201) {

                        alert("Running out of Money in future! Reduce expenses or increase returns.")
                        setLoading(false);
                        setDatafetched(true);
                    }
                    else {
                        const gldgraph = JSON.parse(response.data[0]);
                        const incgraph = JSON.parse(response.data[1]);
                        const expgraph = JSON.parse(response.data[2]);
                        const scapgraph = JSON.parse(response.data[3]);
                        // Access the properties of each object
                        //console.log(expgraph.data)
                        setGLDgraphData(gldgraph.data);
                        setRatiographData(incgraph.data);
                        setEXPgraphData(expgraph.data);
                        setScapgraphData(scapgraph.data);
                        setGLDgraphlayout(gldgraph.layout);
                        setRatiographlayout(incgraph.layout);
                        setEXPgraphlayout(expgraph.layout);
                        setScapgraphlayout(scapgraph.layout);
                        setLoading(false);
                        setDatafetched(true);

                        //console.log('API Data:', response.data);
                    }
                } catch (error) {
                    console.error('Error fetching graph data:', error);
                    setLoading(false);
                }
            }
    };

    
        fetchData(datafetched);
         
       
    });
    function plotInContainer(data, containerId, graphLayout) {
        Plotly.newPlot(containerId, data, graphLayout, config);
    }
    // useEffect to plot data when graphData changes
    useEffect(() => {
        //console.log('plot effect');
        if (gldgraphData) {
            // Plot within the custom container after fetching data
            plotInContainer(gldgraphData, 'plot1', gldgraphlayout);
        }
        if (ratiographData) {
            // Plot within the custom container after fetching data
            plotInContainer(ratiographData, 'plot3', ratiographlayout);
        }
        if (expgraphData) {
            // Plot within the custom container after fetching data
            plotInContainer(expgraphData, 'plot4', expgraphlayout);
        }

        if (scapgraphData) {
            // Plot within the custom container after fetching data
            plotInContainer(scapgraphData, 'plot2', scapgraphlayout);
        }

    }, [gldgraphData, ratiographData, expgraphData, scapgraphData, gldgraphlayout, scapgraphlayout, ratiographlayout, expgraphlayout]);

    const PlotComponent = ({ id, loading, customStyle }) => (
        <div id={id} className={`${customStyle}`}>
            {loading && <LoadingAnimation />}
            {/* Content for the plot */}
        </div>
    );


    return (
        
        <div id="graphContainer" className={styles.GraphContainer}>
            {/* Plot 1 */}
            <div id="plot1" className={styles.Plot}>
                <PlotComponent id="plot1" loading={loading} customStyle={styles.Plot1Style} />
            </div>

            {/* Plot 2 */}
            <div id="plot2" className={styles.Plot}>
                <PlotComponent id="plot2" loading={loading} customStyle={styles.Plot2Style} />
            </div>

            {/* Plot 3 */}
            <div id="plot3" className={styles.Plot}>
                <PlotComponent id="plot3" loading={loading} customStyle={styles.Plot3Style} />
            </div>

            {/* Plot 4 */}
            <div id="plot4" className={styles.Plot}>
                <PlotComponent id="plot4" loading={loading} customStyle={styles.Plot4Style} />
            </div>
        </div>

           
    );
};

export default GldPlotly;
