// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import '@fortawesome/fontawesome-svg-core/styles.css';
import styles from './App.module.css'; // Import your styles
import { useAuth } from './AuthContext';
import CanonicalLink from './CanonicalLink'; // Import the CanonicalLink component
import Header from './comp/Header';
import HeroSection from './comp/HeroSection';
import FinancialDetailsSection from './comp/FinancialDetailsSection';
import Footer from './comp/Footer';
import TermsPrivacyPage from './comp/TermsPrivacyPage';
import GldPlotly from './comp/GldPlotly';
import GoogleSignIn from './comp/GoogleSignIn';
import GoogleSignup from './comp/GoogleSignup';
import GoogleAuth from './comp/GoogleAuth';
import Profilebasic from './comp/Profilebasic';
import Headerlogged from './comp/Headerlogged';
import Wishhes from './comp/Wishhes';
import Flows from './comp/Flows';
import Networth from './comp/Networth';
import NetworthPlotly from './comp/NetworthPlotly';
import Prefer from './comp/Prefer';


function App() {
    const { userData } = useAuth(); // Use the userData state from AuthProvider

     const [showFinancialDetails, setShowFinancialDetails] = useState(false);
    const [showGldPlotly, setShowGldPlotly] = useState(false);
    const [showGetStarted, setShowGetStarted] = useState(false);
    const [showNetworthPlotly, setShowNetworthPlotly] = useState(false);

    const handleButtonClick = (navigate) => {
    // Handle button click logic
    setShowFinancialDetails(true);
    // Fetch data only once during mount
   
    navigate('/'); // Assuming the default route is '/'
  };

    const handleFinancialDetailsButtonClick = () => {
        // Set state to false to simulate unmounting
        setShowGldPlotly(false);

        // Use setTimeout to delay and then set state to true
        setTimeout(() => {
            setShowGldPlotly(true);
        }, 500); // Adjust the delay time (in milliseconds) as needed
    };

    const handleNetworthButtonClick = () => {
        // Set state to false to simulate unmounting
        setShowNetworthPlotly(false);

        // Use setTimeout to delay and then set state to true
        setTimeout(() => {
            setShowNetworthPlotly(true);
        }, 500); // Adjust the delay time (in milliseconds) as needed
    };


  useEffect(() => {
    if (showFinancialDetails) {
      // Do something when showFinancialDetails is true
    }
    
  }, [showFinancialDetails, userData]);

 

    return (
        
              <BrowserRouter>
            <div className={styles.App}>
                {userData.loggedin ? (
                    <Headerlogged />
                  ) : (
                    <Header
                      showGetStarted={showGetStarted}
                      setShowGetStarted={setShowGetStarted}
                    />
                  )}
                   
                  <Routes>
                        <Route
                            path="/signin"
                            element={userData.loggedin ? <Navigate to="/" /> : <React.Fragment><GoogleSignIn /></React.Fragment>}
                        />
                        <Route
                            path="/signup"
                            element={userData.loggedin ? <Navigate to="/" /> : <React.Fragment><GoogleSignup /></React.Fragment>}
                        />
                   <Route
                        path="/terms-privacy"
                        element={<React.Fragment><TermsPrivacyPage /></React.Fragment>}
                    />
                    <Route
                        path="/wishhes"
                        element={<React.Fragment><Wishhes onButtonClick={handleFinancialDetailsButtonClick} />
                            {showGldPlotly && <GldPlotly />}</React.Fragment>}
                    />
                     <Route
                        path="/flows"
                        element={<React.Fragment><Flows /></React.Fragment>}
                    />
                    <Route
                        path="/prefer"
                        element={<React.Fragment><Prefer /></React.Fragment>}
                    />
                    <Route
                        path="/networth"
                        element={<React.Fragment><Networth onButtonClick={handleNetworthButtonClick} />
                            {showNetworthPlotly && <NetworthPlotly />}</React.Fragment>}
                    />
                    <Route path="/codecallback" element={<React.Fragment><GoogleAuth /></React.Fragment>} />
                    <Route path="/" element={<React.Fragment> {/* or <></> for short */}
                     <HeroSection setShowGetStarted={setShowGetStarted} onButtonClick={handleButtonClick} />
                                  {showFinancialDetails && (<FinancialDetailsSection
                                      onButtonClick={handleFinancialDetailsButtonClick}
                                  />
                                  )}
                                  {showGldPlotly && <GldPlotly />}
           
                    </React.Fragment>} />
                    <Route path="/profile" element={<React.Fragment><Profilebasic  /></React.Fragment>} />
                  </Routes>
                  <CanonicalLink /> {/* Include CanonicalLink at the end of your App component */}
                 <div className={styles.mainContent}></div>
                 <Footer />
                </div>
            </BrowserRouter>
       
    );
};

export default App;

