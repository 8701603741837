

import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa'; // Importing the delete icon from the react-icons library
import styles from './Networth.module.css'; // Import the CSS module
import Button from './Button'; // Import the Button component
import { apiDomain } from './Domain'; // Import the apiDomain variable
import { useAuth } from '../AuthContext';
import {checkUserData} from '../utils/session';
import { logout } from '../utils/utils';

const Networth = ({ onButtonClick }) => {
    
    const { userData } = useAuth(); // Use the userData state from AuthProvider
     const navigate = useNavigate();

    const fadeIn = useSpring({
    opacity: window.scrollY > 200 ? 1 : 0, // Adjust the scroll threshold as needed
    from: { opacity: 0 },
  });

  const [notification, setNotification] = useState('');
    const [loading, setLoading] = useState(false);
   
  const handleButtonClick = async () => {

      // Reset previous notifications
    setNotification('');
     setLoading(true);
    // Validate that all fields are filled
      if ((
          mf === '' && mftag === '' &&
          stocks === '' && stockstag === '' &&
          others === '' && otherstag === '' &&
          epf === '' && epftag === '' &&
          ppf === '' && ppftag === '' &&
          nps === '' && npstag === '' &&
          esop === '' && esoptag === '' &&
          insurance === '' && insurancetag === '' &&
          bonds === '' && bondstag === '' &&
          fd === '' && fdtag === ''
      ) || (mf !== '' && mftag === '') ||
          (stocks !== '' && stockstag === '') ||
          (others !== '' && otherstag === '') ||
          (epf !== '' && epftag === '') ||
          (ppf !== '' && ppftag === '') ||
          (nps !== '' && npstag === '') ||
          (esop !== '' && esoptag === '') ||
          (insurance !== '' && insurancetag === '') ||
          (bonds !== '' && bondstag === '') ||
          (fd !== '' && fdtag === '')
 || !date ) {
      setNotification('Some fields are missing.');
      // Automatically clear the notification after 3 seconds (3000 milliseconds)
      setTimeout(() => {
        setNotification('');
        setLoading(false);
      },  1000);
      return;
    }

 


  // Gather data from state variables
  const products = {
   mf,mftag,
   stocks,stockstag,
   others,otherstag,
    epf,epftag,
    ppf,ppftag,
    nps,npstag,
    esop,esoptag,
    insurance,insurancetag,
    bonds,bondstag,
    fd,fdtag,   
  };
  // Filter out fields with empty strings
const filteredProducts = Object.fromEntries(
  Object.entries(products).filter(([_, value]) => value !== "")
);


const postData = {
  products: filteredProducts,
  date: date,
  
};


      // Send data to Flask backend using Axios
      try {
      const apiUrl = `${apiDomain}/api/submit-networth-data`;
      const response = await axios.post(apiUrl, postData,{
      method: 'POST',
      mode: 'cors',
      withCredentials: true, // Include credentials (cookies) with the request
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-App-Identifier': 'wishh/1.0',
        // Other headers as needed
      },
    });
    
    const fetch_data = response.data;
    if (fetch_data.error) {

        alert("Please try again!");
        await logout(navigate);
    }

   else {
      // Handle the response from the backend
        console.log('API Response Message:', response.data.message);
        alert(response.data.message)
        onButtonClick();
     }   
    }
    catch(error) {
      console.error('Error posting data:', error.message);
      // Handle errors or provide user-friendly messages as needed
    }
    finally {
        // Reset loading state after the request is complete
        setLoading(false);
      }
};




useEffect(() => {
  const handleScroll = () => {
    // Update the fade-in effect based on the scroll position
    fadeIn.opacity.set(window.scrollY > 0 ? 1 : 0);
  };

  // Attach the event listener when the component mounts
  window.addEventListener('scroll', handleScroll);

  // Check user data and navigate if needed
  if (checkUserData(userData)) {
    // session expired go to home page
    alert('Session Expired! Login Again.');
    navigate('/');
  }

  // Clean up the event listener when the component unmounts
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };

}, [fadeIn.opacity, userData, navigate]); 

    
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make a GET request to fetch data from the Flask backend
        const apiUrl = `${apiDomain}/api/fetch-networth-data`;
        const response = await axios.get(apiUrl, {
          
          method: 'POST',
          mode: 'cors',
          withCredentials: true, // Include credentials (cookies) with the request
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-App-Identifier': 'wishh/1.0',
            // Other headers as needed
        }
        });
        const fetch_data = response.data
        if (fetch_data.error) {
        
            
        alert("Please try again!");

            await logout(navigate);
        
        }
        else {
            // Check if data is available in the response
        const responseData = response.data.data.products;
        const dateData = response.data.data;
        if (responseData) {
          // Update state variables with fetched data
        
        setMF(responseData.mf || '');
        setMFtag(responseData.mftag || '');
        setStocks(responseData.stocks || '');
        setStockstag(responseData.stockstag || '');
            setEPF(responseData.epf || '');
            setEPFtag(responseData.epftag || '');
            setPPF(responseData.ppf || '');
            setPPFtag(responseData.ppftag || '');
            setNPS(responseData.nps || '');
            setNPStag(responseData.npstag || '');
            setESOP(responseData.esop || '');
            setESOPtag(responseData.esoptag || '');
            setInsurance(responseData.insurance || '');
            setInsurancetag(responseData.insurancetag || '');
            setBonds(responseData.bonds || '');
            setBondstag(responseData.bondstag || '');
            setFD(responseData.fd || '');
            setFDtag(responseData.fdtag || '');
             setOthers(responseData.fd || '');
            setOtherstag(responseData.fdtag || '');
            setLoans(responseData.pl || '');
        setDate(dateData.date || '');
          
        }
            // Optionally, you can also trigger any necessary actions or updates here
           
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
        // Handle errors or provide user-friendly messages as needed
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, [navigate]); // The empty dependency array ensures that this effect runs only once on mount

 
  const options = [
    { label: 'Equity', value: 0.12 },
    { label: 'Moderate', value: 0.10 },
    { label: 'Debt', value: 0.08 },
    { label: 'Savings', value: 0.05 },
  ];

     const [mf, setMF] = useState('');
     const [stocks, setStocks] = useState('');
     const [fd, setFD] = useState('');
   const [epf, setEPF] = useState('');
    const [others, setOthers] = useState('');
   const [ppf, setPPF] = useState('');
   const [nps, setNPS] = useState('');
   const [esop, setESOP] = useState('');
   const [insurance, setInsurance] = useState('');
  const [bonds, setBonds] = useState('');
 
   
  const [epftag, setEPFtag] = useState('');
  const [stockstag, setStockstag] = useState('');
   const [mftag, setMFtag] = useState('');
   const [fdtag, setFDtag] = useState('');
   const [npstag, setNPStag] = useState('');
    const [otherstag, setOtherstag] = useState('');
   const [esoptag, setESOPtag] = useState('');
   const [insurancetag, setInsurancetag] = useState('');
   const [bondstag, setBondstag] = useState('');
   const [ppftag, setPPFtag] = useState('');
    
    const [loans, setLoans] = useState('');
    const [date, setDate] = useState('');
         const [activeTab, setActiveTab] = useState('risky'); // Default to 'self' tab   
    
 

  return (
     <animated.section className={styles['profile-section']} >
      <div className={styles.container}>
      <h2 className={styles.heading}>Household Networth</h2>
      <div className={styles.customp}>
                    Enter details as a couple, if married
              </div>
         {/* Tab Navigation */}
            <div className={styles.tabContainer}>
              <div
                className={`${styles.tab} ${activeTab === 'risky' && styles.activeTab}`}
                onClick={() => setActiveTab('risky')}
              >
                Risky
              </div>
               <div
                className={`${styles.tab} ${activeTab === 'safer' && styles.activeTab}`}
                onClick={() => setActiveTab('safer')}
              >
                Safer
              </div>
               <div
                className={`${styles.tab} ${activeTab === 'safe' && styles.activeTab}`}
                onClick={() => setActiveTab('safe')}
              >
                Safe
              </div>
              <div
                className={`${styles.tab} ${activeTab === 'insurance' && styles.activeTab}`}
                onClick={() => setActiveTab('insurance')}
              >
                Insurance
              </div>
              
              <div
                className={`${styles.tab} ${activeTab === 'loans' && styles.activeTab}`}
                onClick={() => alert('Coming Soon')}
              >
                Loans
              </div>
               <div
                className={`${styles.tab} ${activeTab === 'property' && styles.activeTab}`}
                onClick={() => alert('Coming Soon')}
              >
                Property
              </div>
              {/* Add more tabs as needed for other roles */}
            </div>

      <div className={styles.cardContainer}>
       <div className={styles.inputContainer}>
        <div className={styles.dateRow}>
          <label htmlFor="date" className={styles.label}>Record Date:</label>
                      {/*<span className={styles.value}>{dob}</span>*/}
        
        <input
        id="date"
            name="date"
                      type="date"
                      value={date}
          onChange={(e) => setDate(e.target.value)}
                      className={styles.dateInput}
        />
          </div>
      </div>
    <div className={`${styles.card} ${activeTab === 'risky' && styles.activeCard}`}>

     <div className={styles.inputContainer}>
      <div className={styles.labelRow}>
                              <label className={styles.label}>Stocks:</label>

        <select value={stockstag} onChange={(e) => setStockstag(e.target.value)} className={styles.tagSelect}>
        <option value="" >Select an option</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label} ({(option.value * 100).toFixed(0)}%)
            </option>
          ))}
                              </select>
                              {stocks !== "" && (
                              <span className={styles.value}>&#x20B9; {stocks.toLocaleString()}
                                      <FaTrash className={styles.deleteIcon} onClick={() => { setStocks(''); setStockstag(''); }} />
                              </span>
                              )}
      </div>
      <input
        type="range"
        min="100000"
        max="10000000"
        step="10000"
        value={stocks}
        onChange={(e) => setStocks(parseInt(e.target.value))}
        className={styles.inputRange}
      />
    </div>
    
     <div className={styles.inputContainer}>
      <div className={styles.labelRow}>
        <label className={styles.label}>MF:</label>
        <select value={mftag} onChange={(e) => setMFtag(e.target.value)} className={styles.tagSelect}>
        <option value="" >Select an option</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label} ({(option.value * 100).toFixed(0)}%)
            </option>
          ))}
                              </select>
                              {mf !== "" && (
                                  <span className={styles.value}>&#x20B9; {mf.toLocaleString()}
                                      <FaTrash className={styles.deleteIcon} onClick={() => { setMF(''); setMFtag(''); }} />
                                  </span>
                              )}
      </div>
      <input
        type="range"
        min="100000"
        max="10000000"
        step="10000"
        value={mf}
        onChange={(e) => setMF(parseInt(e.target.value))}
        className={styles.inputRange}
      />
    </div>
     <div className={styles.inputContainer}>
      <div className={styles.labelRow}>
        <label className={styles.label}>ESOP:</label>
        <select value={esoptag} onChange={(e) => setESOPtag(e.target.value)} className={styles.tagSelect}>
        <option value="" >Select an option</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label} ({(option.value * 100).toFixed(0)}%)
            </option>
          ))}
                              </select>
                              {esop !== "" && (
                                  <span className={styles.value}>&#x20B9; {esop.toLocaleString()}
                                      <FaTrash className={styles.deleteIcon} onClick={() => { setESOP(''); setESOPtag(''); }} />
                                  </span>
                              )}
      </div>
      <input
        type="range"
        min="100000"
        max="10000000"
        step="10000"
        value={esop}
        onChange={(e) => setESOP(parseInt(e.target.value))}
        className={styles.inputRange}
      />
    </div>
     
      </div>
       <div className={`${styles.card} ${activeTab === 'safer' && styles.activeCard}`}>
        <div className={styles.inputContainer}>
      <div className={styles.labelRow}>
        <label className={styles.label}>NPS:</label>
        <select value={npstag} onChange={(e) => setNPStag(e.target.value)} className={styles.tagSelect}>
        <option value="" >Select an option</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label} ({(option.value * 100).toFixed(0)}%)
            </option>
          ))}
        </select>
                              {nps !== "" && (
                                  <span className={styles.value}>&#x20B9; {nps.toLocaleString()}
                                      <FaTrash className={styles.deleteIcon} onClick={() => { setNPS(''); setNPStag(''); }} />
                                  </span>
                              )}
      </div>
      <input
        type="range"
        min="100000"
        max="10000000"
        step="10000"
        value={nps}
        onChange={(e) => setNPS(parseInt(e.target.value))}
        className={styles.inputRange}
      />
    </div>

    <div className={styles.inputContainer}>
      <div className={styles.labelRow}>
        <label className={styles.label}>Bonds:</label>
        <select value={bondstag} onChange={(e) => setBondstag(e.target.value)} className={styles.tagSelect}>
        <option value="" >Select an option</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label} ({(option.value * 100).toFixed(0)}%)
            </option>
          ))}
                              </select>
                              {bonds !== "" && (
                              <span className={styles.value}>&#x20B9; {bonds.toLocaleString()}
                                  <FaTrash className={styles.deleteIcon} onClick={() => { setBonds(''); setBondstag(''); }} />
                              </span>
                              )}
      </div>
      <input
        type="range"
        min="100000"
        max="10000000"
        step="10000"
        value={bonds}
        onChange={(e) => setBonds(parseInt(e.target.value))}
        className={styles.inputRange}
      />
    </div>
     <div className={styles.inputContainer}>
      <div className={styles.labelRow}>
        <label className={styles.label}>Others:</label>
        <select value={otherstag} onChange={(e) => setOtherstag(e.target.value)} className={styles.tagSelect}>
        <option value="" >Select an option</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label} ({(option.value * 100).toFixed(0)}%)
            </option>
          ))}
                              </select>
                              {others !== "" && (
                                  <span className={styles.value}>&#x20B9; {others.toLocaleString()}
                                      <FaTrash className={styles.deleteIcon} onClick={() => { setOthers(''); setOtherstag(''); }} />
                                  </span>
                              )}
      </div>
      <input
        type="range"
        min="100000"
        max="10000000"
        step="10000"
        value={others}
        onChange={(e) => setOthers(parseInt(e.target.value))}
        className={styles.inputRange}
      />
    </div>

       </div>
       <div className={`${styles.card} ${activeTab === 'safe' && styles.activeCard}`}>

        <div className={styles.inputContainer}>
      <div className={styles.labelRow}>
        <label className={styles.label}>EPF:</label>
        <select value={epftag} onChange={(e) => setEPFtag(e.target.value)} className={styles.tagSelect}>
        <option value="" >Select an option</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label} ({(option.value * 100).toFixed(0)}%)
            </option>
          ))}
                              </select>
                              {epf !== "" && (
                                  <span className={styles.value}>&#x20B9; {epf.toLocaleString()}
                                      <FaTrash className={styles.deleteIcon} onClick={() => { setEPF(''); setEPFtag(''); }} />
                                  </span>
                              )}
      </div>
      <input
        type="range"
        min="100000"
        max="10000000"
        step="10000"
        value={epf}
        onChange={(e) => setEPF(parseInt(e.target.value))}
        className={styles.inputRange}
      />
    </div>
     <div className={styles.inputContainer}>
      <div className={styles.labelRow}>
        <label className={styles.label}>PPF:</label>
        <select value={ppftag} onChange={(e) => setPPFtag(e.target.value)} className={styles.tagSelect}>
        <option value="" >Select an option</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label} ({(option.value * 100).toFixed(0)}%)
            </option>
          ))}
        </select>

                              {ppf !== "" && (
                                  <span className={styles.value}>&#x20B9; {ppf.toLocaleString()}
                                      <FaTrash className={styles.deleteIcon} onClick={() => { setPPF(''); setPPFtag(''); }} />
                                  </span>
                              )}
      </div>
      <input
        type="range"
        min="100000"
        max="10000000"
        step="10000"
        value={ppf}
        onChange={(e) => setPPF(parseInt(e.target.value))}
        className={styles.inputRange}
      />
    </div>
     <div className={styles.inputContainer}>
      <div className={styles.labelRow}>
        <label className={styles.label}>FDs:</label>
        <select value={fdtag} onChange={(e) => setFDtag(e.target.value)} className={styles.tagSelect}>
        <option value="" >Select an option</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label} ({(option.value * 100).toFixed(0)}%)
            </option>
          ))}
        </select>

                              {fd !== "" && (
                                  <span className={styles.value}>&#x20B9; {fd.toLocaleString()}
                                      <FaTrash className={styles.deleteIcon} onClick={() => { setFD(''); setFDtag(''); }} />
                                  </span>
                              )}
      </div>
      <input
        type="range"
        min="100000"
        max="10000000"
        step="10000"
        value={fd}
        onChange={(e) => setFD(parseInt(e.target.value))}
        className={styles.inputRange}
      />
    </div>
       </div>
        <div className={`${styles.card} ${activeTab === 'insurance' && styles.activeCard}`}>
         <div className={styles.inputContainer}>
      <div className={styles.labelRow}>
        <label className={styles.label}>Insurance:</label>
        <select value={insurancetag} onChange={(e) => setInsurancetag(e.target.value)} className={styles.tagSelect}>
        <option value="" >Select an option</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label} ({(option.value * 100).toFixed(0)}%)
            </option>
          ))}
                              </select>
                              {insurance !== "" && (
                                  <span className={styles.value}>&#x20B9; {insurance.toLocaleString()}
                                      <FaTrash className={styles.deleteIcon} onClick={() => { setInsurance(''); setInsurancetag(''); }} />
                                  </span>
                              )}
      </div>
      <input
        type="range"
        min="100000"
        max="10000000"
        step="10000"
        value={insurance}
        onChange={(e) => setInsurance(parseInt(e.target.value))}
        className={styles.inputRange}
      />
    </div>
       </div>

         
 <div className={`${styles.card} ${activeTab === 'loans' && styles.activeCard}`}>
     
  <div className={styles.inputContainer}>
      <div className={styles.labelRow}>
        <label className={styles.label}>Loans:</label>
        
        <span className={styles.value}>&#x20B9; {loans.toLocaleString()}</span>
      </div>
      <input
        type="range"
        min="100000"
        max="10000000"
        step="10000"
        value={loans}
        onChange={(e) => setLoans(parseInt(e.target.value))}
        className={styles.inputRange}
      />
    </div>
     
      </div>
       <div className={`${styles.card} ${activeTab === 'property' && styles.activeCard}`}>

    
      </div>
        </div>
      <div className={styles.connectButton}>
          <Button label='Submit' onClick={handleButtonClick} disabled={loading}></Button>

              </div>
              {notification && <p style={{ color: 'yellow',fontSize: '10px'  }}>{notification}</p>}
    </div>
        
     </animated.section>
  );
};

export default Networth;
