import React, { useEffect} from 'react';
import Chart from 'chart.js/auto';
import styles from './Moneyshape.module.css';
import { useSpring } from 'react-spring';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { checkUserData } from '../utils/session';

const Moneyshape = () => {
   
    const { userData } = useAuth(); // Use the userData state from AuthProvider
    const navigate = useNavigate();

    const fadeIn = useSpring({
        opacity: window.scrollY > 200 ? 1 : 0, // Adjust the scroll threshold as needed
        from: { opacity: 0 },
    });

    useEffect(() => {
        const handleScroll = () => {
            // Update the fade-in effect based on the scroll position
            fadeIn.opacity.set(window.scrollY > 0 ? 1 : 0);
        };

        // Attach the event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Check user data and navigate if needed
        if (checkUserData(userData)) {
            // session expired go to home page
            alert('Session Expired! Login Again.');
            navigate('/');
        }

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, [fadeIn.opacity, userData, navigate]); 

    
        
    useEffect(() => {
        const fetchedJsonString = localStorage.getItem('moneyshape');
    function createRadarChart(canvasId) {
            // Fetch the stored data from local storage
            const fetchedData = JSON.parse(fetchedJsonString);
        const code1 = fetchedData.code1;
        const code2 = fetchedData.code2;
        const weights1 = fetchedData.weights1;
        const weights2 = fetchedData.weights2;
            const labels = fetchedData.labels;

        var userNames = [code1, code2];

        // Create datasets for each user
        var datasets = userNames.map((userName, index) => ({
            label: userName,
            data: index === 0 ? weights1 : weights2,
            backgroundColor: index === 1 ? 'rgba(0,0,0,0)' : `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.5)`,
            borderColor: `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},1)`,
            borderWidth: 1
        }));


        // Access the canvas element and get its context
        const canvas = document.getElementById(canvasId);

        if (canvas) {
            const ctx = canvas.getContext('2d');

            // Destroy existing chart if it exists
            if (canvas.chart) {
                canvas.chart.destroy();
            }


            // Create the radar chart
            const radarChart = new Chart(ctx, {
                type: 'radar',
                data: {
                    labels: labels,
                    datasets: datasets
                },
                options: {
                    scales: {
                        r: {
                            beginAtZero: true,
                            min: 0,
                            max: 10,
                            stepSize: 1,
                            grid: {
                                display: true,
                                color: 'darkgrey', // Adjust the color as needed
                                lineWidth: 0.2, // Adjust the line width as needed
                            },
                           
                        },
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: 'Relative Importance of Preferences. 10 is most important.',
                            color: '#FF9900',
                            font: {
                                size: 15,
                                weight: 'light', // Set the weight to 'bold'
                            },
                        },
                        legend: {
                            display: true,
                            labels: {
                                font: {
                                    size: 12,
                                },
                            },
                        },
                    },
                },
            });
            // Store the chart instance on the canvas element
            canvas.chart = radarChart;
        }
           
        }

        if (fetchedJsonString) {
            createRadarChart("radarChart");
        }
        // Cleanup function to destroy the chart when the component unmounts
        return () => {
            const canvas = document.getElementById("radarChart");
            if (canvas && canvas.chart) {
                canvas.chart.destroy();
            }
        };

        });

    return (
        <div id="graphContainer" className={styles.GraphContainer}>
            <h2 className={styles.heading}>This is your Moneyshape</h2>
            {/* Radar Chart */}
            <canvas id="radarChart" className={styles.RadarChart} ></canvas>
        </div>
    );
};

export default Moneyshape;
