// FinancialDetailsSection.js

import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import axios from 'axios';
import styles from './Financial.module.css'; // Import the CSS module
import Button from './Button'; // Import the Button component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { apiDomain } from './Domain'; // Import the apiDomain variable

const FinancialDetailsSection = ({ onButtonClick }) => {

    const fadeIn = useSpring({
    opacity: window.scrollY > 200 ? 1 : 0, // Adjust the scroll threshold as needed
    from: { opacity: 0 },
  });

  const [notification, setNotification] = useState('');
    const [loading, setLoading] = useState(false);
   
  const handleButtonClick = () => {

      // Reset previous notifications
    setNotification('');
     setLoading(true);
    // Validate that all fields are filled
      if (!age || !income || !assets || !essentials) {
      setNotification('All fields are required');
      // Automatically clear the notification after 3 seconds (3000 milliseconds)
      setTimeout(() => {
        setNotification('');
        setLoading(false);
      },  2000);
      return;
    }

 


  // Gather data from state variables
  const data = {
    age,
    income,
    assets,
    essentials,
      property,
    returns,
    leanfat,
  };

      // Send data to Flask backend using Axios
      const apiUrl = `${apiDomain}/api/submitData`;
      axios.post(apiUrl, data, {
      method: 'POST',
      mode: 'cors',
      withCredentials: true, // Include credentials (cookies) with the request
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-App-Identifier': 'wishh/1.0',
        // Other headers as needed
      },
    })
    .then(response => {
      // Handle the response from the backend
        console.log('API Response Message:', response.data.message);
        // You can also update the state or perform other actions based on the response
        onButtonClick(); // Call the onButtonClick prop to link to GldPlotly display
        
    })
    .catch(error => {
      console.error('Error posting data:', error.message);
      // Handle errors or provide user-friendly messages as needed
    })
    .finally(() => {
        // Reset loading state after the request is complete
        setLoading(false);
      });
};




  useEffect(() => {
    const handleScroll = () => {
      // Update the fade-in effect based on the scroll position
      fadeIn.opacity.set(window.scrollY > 0 ? 1 : 0);
    };

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fadeIn.opacity]); 
    



  const [age, setAge] = useState('');
  const [income, setIncome] = useState('');
    const [assets, setAssets] = useState('');
  const [essentials, setEssentials] = useState('');
  

    const [property, setProperty] = useState('');
    const [returns, setReturns] = useState('');
  const [leanfat, setleanfat] = useState('');

  return (
     <animated.section className={styles['financial-section']} >
      <div className={styles.container}>
      <h2 className={styles.heading}>Household Profile</h2>
      <div className={styles.customp}>
                    Enter details as a couple, if married
                </div>
      <div className={styles.inputContainer}>
        <div className={styles.labelRow}>
          <label className={styles.label}>Age:</label>
          <span className={styles.value}>{age}</span>
        </div>
        <input
          type="range"
          min="18"
          max="65"
          step="1"
          value={age}
          onChange={(e) => setAge(parseInt(e.target.value))}
          className={styles.inputRange}
        />

      </div>

      <div className={styles.inputContainer}>
        <div className={styles.labelRow}>
          <label className={styles.label}> Post tax Income: <span className={styles.customp}>
                    (p.a.)
                </span></label>
          
          <span className={styles.value}>&#x20B9; {income.toLocaleString()}</span>
        </div>
        <input
          type="range"
          min="100000"
          max="10000000"
          step="10000"
          value={income}
          onChange={(e) => setIncome(parseInt(e.target.value))}
          className={styles.inputRange}
        />
      </div>

      <div className={styles.inputContainer}>
      <div className={styles.labelRow}>
        <label className={styles.label}>Financial Assets: </label>
                      <span className={styles.value}>&#x20B9; {assets.toLocaleString()}</span>
        </div>
        <input
          type="range"
          min="0"
          max="10000000"
          step="100000"
                      value={assets}
                      onChange={(e) => setAssets(parseInt(e.target.value))}
          className={styles.inputRange}
        />
      </div>

      <div className={styles.inputContainer}>
      <div className={styles.labelRow}>
        <label className={styles.label}>Living expenses: <span className={styles.customp}>
                    (p.m.)
                </span></label>
        <span className={styles.value}>&#x20B9; {essentials.toLocaleString()}</span>
        </div>
        <input
          type="range"
          min="0"
          max="500000"
          step="10000"
          value={essentials}
          onChange={(e) => setEssentials(parseInt(e.target.value))}
          className={styles.inputRange}
        />
      </div>

      <div className={styles.inputContainer}>
                  <label className={styles.singlelabel}>Property:

                      <FontAwesomeIcon icon={faLock} className={styles.lockIcon} />

                  </label>
        <select
          value={property}
          onChange={(e) => setProperty(e.target.value)}
          className={styles.inputSelect}
          disabled
        >
          {/*<option value="">Select</option>*/}
          {/*<option value="yes">Have property</option>*/}
          <option value="no">Want to purchase</option>
          
        </select>
      </div>
              <div className={styles.inputContainer}>
                  <label className={styles.singlelabel}>
                      Returns:
                      <FontAwesomeIcon icon={faLock} className={styles.lockIcon} />
                  </label>
                  <select
                      value={returns}
                      onChange={(e) => setReturns(e.target.value)}
                      className={styles.inputSelect}
                      disabled
                  >

                      {/*<option value="">Select</option>*/}
                      {/*<option value="low">Low</option>*/}
                      <option value="moderate">Moderate</option>
                      {/*<option value="aggressive">Aggressive</option>*/}
                  </select>
              </div>
      <div className={styles.inputContainer}>
                  <label className={styles.singlelabel}>
                      Lean Vs FAT:
                      <FontAwesomeIcon icon={faLock} className={styles.lockIcon} />
                  </label>
        <select
          value={leanfat}
          onChange={(e) => setleanfat(e.target.value)}
          className={styles.inputSelect}
          disabled
        >

          {/*<option value="">Select</option>*/}
          {/*<option value="low">Low</option>*/}
          <option value="moderate">Current</option>
          {/*<option value="aggressive">Aggressive</option>*/}
        </select>
      </div>
      <div className={styles.connectButton}>
          <Button label='Submit' onClick={handleButtonClick} disabled={loading}></Button>

              </div>
              {notification && <p style={{ color: 'black' }}>{notification}</p>}
    </div>
          
     </animated.section>
  );
};

export default FinancialDetailsSection;
