// Filename - Progress_bar.js

import React from 'react'

const Progressbar = ({ bgcolor, progress, total, height, textSize }) => {

	const Parentdiv = {
		height: height,
		width: '100%',
		backgroundColor: 'grey',
		borderRadius: 0,
		margin: 0,
		overflow: 'hidden', // Ensure child doesn't overflow
		display: 'flex', // Enable flexbox
		alignItems: 'center', // Center align vertically
	}

	const Childdiv = {
		height: '100%',
		width: `${(progress / total) * 100}%`,
		backgroundColor: bgcolor,
		borderRadius: 0,
		textAlign: 'right',
		margin: 0,
		transition: 'width 0.3s ease', // Smooth transition for width changes
		display: 'flex', // Enable flexbox
		alignItems: 'center', // Center align vertically
	}

	const progresstext = {
		padding: 0,
		color: 'black',
		fontWeight: 600,
		fontSize: textSize || '1em', // Set a default font size or use the provided one

		
	}

	return (
		<div style={Parentdiv}>
			<div style={Childdiv}>
				<span style={progresstext}>{`${progress}/${total}`}</span>
			</div>
		</div>
	)
}

export default Progressbar;
