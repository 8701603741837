import React, { useState } from 'react';
import ButtonSec from './Button_sec'; // Import the Button component
import styles from './Headerlogged.module.css'; // Import your CSS file
import { useAuth } from '../AuthContext'; // Import the useAuth hook
import { logout } from '../utils/utils';
import LoadingAnimation from './LoadingAnimation';
import { useNavigate, Link, useLocation  } from 'react-router-dom';

const Headerlogged = () => {
    const [loading, setLoading] = useState();
     const navigate = useNavigate();
    const { userData } = useAuth();
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const handleScroll = () => {
        const header = document.querySelector(`.${styles.header}`);
        const scrollThreshold = 10; // Adjust the scroll threshold as needed

        if (window.scrollY > scrollThreshold) {
            header.classList.add(styles.fixed);
        } else {
            header.classList.remove(styles.fixed);
        }
    };

    // Debounce the scroll event
    const debouncedScrollHandler = debounce(handleScroll, 10); // Adjust delay as needed


    // Add the debounced scroll event listener
    React.useEffect(() => {
        const handleScrollDebounced = () => {
            debouncedScrollHandler(); // Call the debounced function
        };

        window.addEventListener('scroll', handleScrollDebounced);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScrollDebounced);
        };
    }, [debouncedScrollHandler]); // Include debouncedScrollHandler in the dependency array

    const [loggingOut, setLoggingOut] = useState(false);

    const handleLogout = async () => {
        try {
            setLoading(true);
      setLoggingOut(true); // Set state to indicate logout in progress
      await logout(navigate); // Wait for the logout operation
      console.log('logged out');
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      setLoggingOut(false); // Reset state after logout, whether successful or not
    }
  };
  const handleLinkClick = () => {
    setMenuOpen(false); // Close the menu when a link is clicked
  };
   
    return (
        <main>
            {/* Include the section explaining the purpose of the page */}

            {loading ? (
                <LoadingAnimation />
            ) : (
        <header className={`${styles.header} ${menuOpen ? styles.fixed : ''}`}>
            {/* Profile Picture and User Info */}
            <div className={styles.profileContainer}>
                <div className={styles.profilePic}>
                    <img src={userData?.profilePic} alt="Profile" />
                </div>
                <div className={styles.userInfo}>
                    <span className={styles.userCode}>{userData?.code}</span>
                    <span className={styles.userName}>{userData?.name}</span>
                </div>
            </div>
           

        <nav  className={`${styles.nav} ${menuOpen ? styles.open : ''}`}>
                <ul>
                    {/* Link 1 */}
                    
                      <li>
                        <Link to="/profile" onClick={handleLinkClick} style={{ color: location.pathname.includes('/profile') ? '#41FDEF' : 'white'}}>
                          Profile
                        </Link>
                      </li>
                    
                    
                      <li>
                                    <Link to="/flows" onClick={handleLinkClick} style={{ color: location.pathname.includes('/flows') ? '#41FDEF' : 'white' }}>
                          Flows
                        </Link>
                      </li>
                    
                   
                      <li>
                                    <Link to="/networth" onClick={handleLinkClick} style={{ color: location.pathname.includes('/networth') ? '#41FDEF' : 'white' }}>
                          Networth
                        </Link>
                      </li>
                        <li>
                            <Link to="/prefer" onClick={handleLinkClick} style={{ color: location.pathname.includes('/prefer') ? '#41FDEF' : 'white' }}>
                                Preferences</Link>
                        </li>
                   
                      <li>
                                    <Link to="/wishhes" onClick={handleLinkClick} style={{ color: location.pathname.includes('/wishhes') ? '#41FDEF' : 'white' }}>
                          Wishhes
                        </Link>
                      </li>
                    
                     
                  
                    
                   
                    {/* Logout Button */}
                    <div className={styles.connectButton}>
                        <ButtonSec label='Logout' onClick={handleLogout} disabled={loggingOut}></ButtonSec>
                    </div>
                </ul>
  </nav>
       
        <div className={`${styles['burger-menu']} ${menuOpen ? styles.open : ''}`} onClick={toggleMenu}>
            <div className={styles.bar1}></div>
            <div className={styles.bar2}></div>
            <div className={styles.bar3}></div>
        </div>
                    </header>
            )}
        </main>
    );
};

export default Headerlogged;
