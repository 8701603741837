// Prefer.js

import React, { useState, useEffect } from 'react';
import styles from './Prefer.module.css'; // Import the CSS module
import { useSpring, animated } from 'react-spring';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { apiDomain } from './Domain'; // Import the apiDomain variable
import { useAuth } from '../AuthContext';
import { checkUserData } from '../utils/session';
import { logout } from '../utils/utils';
import Progressbar from "./Progress_bar";
import Scrollcards from "./Scrollcards";
import Moneyshape from "./Moneyshape"
import Button from './Button'; // Import the Button component

const Prefer = () => {

    const { userData } = useAuth(); // Use the userData state from AuthProvider
    const navigate = useNavigate();

    const fadeIn = useSpring({
        opacity: window.scrollY > 200 ? 1 : 0, // Adjust the scroll threshold as needed
        from: { opacity: 0 },
    });

    useEffect(() => {
        const handleScroll = () => {
            // Update the fade-in effect based on the scroll position
            fadeIn.opacity.set(window.scrollY > 0 ? 1 : 0);
        };

        // Attach the event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Check user data and navigate if needed
        if (checkUserData(userData)) {
            // session expired go to home page
            alert('Session Expired! Login Again.');
            navigate('/');
        }

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, [fadeIn.opacity, userData, navigate]); 


   
    const [cardPairs, setCardPairs] = useState([]);
    const [activeTab, setActiveTab] = useState(''); // Default to 'self' tab   
    const [editflag, setEditflag] = useState(false); // Default to 'self' tab  
    const [progress, setProgress] = useState(null);
    const [completed, setCompleted] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Make a GET request to fetch data from the Flask backend
                const apiUrl = `${apiDomain}/api/fetch-prefer-data`;
                const response = await axios.post(apiUrl, editflag,{

                    method: 'POST',
                    mode: 'cors',
                    withCredentials: true, // Include credentials (cookies) with the request
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'X-App-Identifier': 'wishh/1.0',
                        // Other headers as needed
                    }
                });
                const fetch_data = response.data
                
                if (fetch_data.code === 401) {


                    alert("Session timed out. Please login again.");

                    await logout(navigate);

                } else if (fetch_data.error) {

                    alert('Something went wrong.  Please login again.');
                    
                    await logout();
                }
                else {
                    
                    if (fetch_data.code === 200) {
                        // 10 cards are submitted and preference saved

                       // console.log(fetch_data.data.level)
                        setScrollCards(true)
                        setActiveTab(fetch_data.data.level)
                        // Convert JSON data to a string for storage
                        const moneyshape = JSON.stringify(fetch_data.data);

                        // Save the JSON string in local storage
                        localStorage.setItem('moneyshape', moneyshape);

                    }
                    else if (fetch_data.code === 201) {

                        setProgress(1)
                        setActiveTab('level1')

                    } else if (fetch_data.code === 500) {
                        // some issue with card pairs
                        alert('Something went wrong.  Please login again.');

                        await logout();
                    }
                   
                }
            } catch (error) {
                console.error('Error fetching data:', error.message);
                // Handle errors or provide user-friendly messages as needed
            }
        };

        // Call the fetchData function when the component mounts
        
        fetchData();


        async function fetchDataAndSetScrollCards() {
            try {
                // Assuming fetchData returns a Promise
                await fetchData();

                // If fetchData completes successfully, setScrollCards to true
                setScrollCards(true);
            } catch (error) {
                // Handle any errors that may occur during fetchData
                console.error('Error fetching data:', error);
                // Optionally handle error scenarios, e.g., setScrollCards(false) or show an error message
            }
        }



        if (completed) {
            fetchDataAndSetScrollCards();
        }

    }, [navigate, editflag, completed]); 

    useEffect(() => {
        const fetchcardData = async () => {
            try {
                // Make a GET request to fetch data from the Flask backend
                const apiUrl = `${apiDomain}/api/get_next_card_pair`;
                const response = await axios.get(apiUrl, {

                    method: 'GET',
                    mode: 'cors',
                    withCredentials: true, // Include credentials (cookies) with the request
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'X-App-Identifier': 'wishh/1.0',
                        // Other headers as needed
                    }
                });
                const fetch_data = response.data
                //console.log(fetch_data)
                if (fetch_data.code === 401) {


                    alert("Session timed out. Please login again.");

                    await logout(navigate);

                } else if (fetch_data.error) {

                    alert('Something went wrong.  Please login again.');

                    await logout();
                }
                else {

                    if (fetch_data.code === 200) {
                        // Update state variables with fetched data
                        const pairs = fetch_data.data.pair
                        console.log(pairs)
                        const cardPair = [
                            { id: pairs[0].id, headline: pairs[0].title, description: pairs[0].description },
                            { id: pairs[1].id, headline: pairs[1].title, description: pairs[1].description },

                            // ... Repeat for all 10 card pairs
                        ];

                        setCardPairs(cardPair);
                        //console.log(cardPair[0])

                    }
                    else if (fetch_data.code === 201) {

                       
                        alert("all cards done")

                    }
                    // Optionally, you can also trigger any necessary actions or updates here
                }
            } catch (error) {
                console.error('Error fetching data:', error.message);
                // Handle errors or provide user-friendly messages as needed
            }
        };
        if (progress !== null && progress < 11) {
            fetchcardData();
        }

    }, [progress,navigate])

   
    const [selectedCard, setSelectedCard] = useState(null);  
    const [otherCard, setOtherCard] = useState(null);  
    const [scrollcards, setScrollCards] = useState(false);  
    

    const fadeInProps = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        config: { duration: 1000 }, 
    });

    const handleCardSelect =  async (cardindex) => {
       
        
        setSelectedCard(cardPairs[cardindex].id);
        setOtherCard(cardPairs[1-cardindex].id);
        //console.log(cardindex)
        //console.log(cardPairs[cardindex].id)
        fadeInProps.opacity.set(0);
        // Set a timer to gradually increase opacity
       
           
        fadeInProps.opacity.start({ opacity: 1 });
       // Adjust the delay (index * 1000 milliseconds)
    };

    useEffect(() => {
        const submitanswer = async () => {

          const  data = {
                'selectedCardId': selectedCard,
              'otherCardId': otherCard
            }

            try {
                // Make a GET request to fetch data from the Flask backend
                const apiUrl = `${apiDomain}/api/submit_answer`;
                const response = await axios.post(apiUrl, data, {

                    method: 'POST',
                    mode: 'cors',
                    withCredentials: true, // Include credentials (cookies) with the request
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'X-App-Identifier': 'wishh/1.0',
                        // Other headers as needed
                    }
                });
                const fetch_data = response.data
                //console.log(fetch_data)
                if (fetch_data.code === 401) {


                    alert("Session timed out. Please login again.");

                    await logout(navigate);

                } else if (fetch_data.error) {

                    alert('Something went wrong.  Please login again.');

                    await logout(navigate);
                }
                else {

                    if (fetch_data.code === 200) {
                        setProgress(fetch_data.data)
                        setSelectedCard(null);
                        setOtherCard(null);
                    }
                    else if (fetch_data.code === 201) {
                        // all 10 preferences saved
                        //console.log(fetch_data.code)
                        alert("Preferences captured. MoneyShape ready.")
                        
                        setProgress(11) //this is to stop further fetch card calls
                        setCompleted(true)
                        
                    }
                    // Optionally, you can also trigger any necessary actions or updates here
                }
            } catch (error) {
                console.error('Error fetching data:', error.message);
                // Handle errors or provide user-friendly messages as needed
            }
        };
        // Check if selectedCard or otherCard has changed before calling submitanswer
        if (selectedCard !== null && otherCard !== null) {
            submitanswer();
        }

    }, [navigate, selectedCard, otherCard])   

    const handleButtonClick = async () => {

        //console.log("clicked")
        setScrollCards(false)
        setEditflag(true)
        setProgress(1)
    }
   
    

    return (
        <>
        <animated.section className={styles['profile-section']} >
            <div className={styles.container}>
                <h2 className={styles.heading}>Preferences</h2>
                <div className={styles.customp}>
                    Enter details as a couple, if married
                </div>
                {/* Tab Navigation */}
                <div className={styles.tabContainer}>
                    <div
                        className={`${styles.tab} ${activeTab === 'level1' && styles.activeTab}`}
                        onClick={() => setActiveTab('level1')}
                    >
                        Level 1
                    </div>
                    <div
                        className={`${styles.tab} ${activeTab === 'level2' && styles.activeTab}`}
                        onClick={() => alert('Coming Soon')}//onClick={() => setActiveTab('level2')}
                    >
                        Level 2
                    </div>
                    <div
                        className={`${styles.tab} ${activeTab === 'level3' && styles.activeTab}`}
                        onClick={() => alert('Coming Soon')}// setActiveTab('inflows')}
                    >
                        Level 3
                    </div>
                    {/* Add more tabs as needed for other roles */}
                </div>

                <div className={`${styles.cardSelector} ${activeTab === 'level1' && styles.activeitem}`}>

                    {/* Conditionally render either the current component or ScrollCard */}
                    {scrollcards ? (
                        <React.Fragment>
                            <Scrollcards />
                            <div className={styles.connectButton}>
                                <Button label='Edit' onClick={handleButtonClick}></Button>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className={styles.heading3}>
                                Which of the two do you prefer?
                            </div>
                            <div className={styles['progress-bar-container']}>
                                <Progressbar
                                    bgcolor="#FF9900"
                                    progress={progress}
                                    total="10"
                                    textSize="0.5em"
                                    height={10}
                                />
                            </div>
                            {
                                cardPairs.map((card, index) => (
                                    <animated.div key={index} className={`${styles.card} ${selectedCard === card.id ? styles.selected : ''}`}
                                        style={fadeInProps}>

                                        <input
                                            type="radio"
                                            id={`card-${card.id}`}
                                            name="card-selector"
                                            value={card.id}
                                            checked={selectedCard === index}
                                            onChange={() => handleCardSelect(index)}
                                        />
                                        <label htmlFor={`card-${card.id}`}>
                                            <h3 className={styles.heading2}>{card.headline}</h3>
                                            <p className={styles.customp2}>{card.description}</p>
                                        </label>
                                    </animated.div>
                                ))
                            }
                        </React.Fragment>
                    )}
                </div>
            </div>
        </animated.section>
            <React.Fragment>{scrollcards && <Moneyshape />}</React.Fragment>
         </>
    );
};

export default Prefer;
