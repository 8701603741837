import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Plotly from 'plotly.js/lib/core';
import Pie from 'plotly.js/lib/pie';
import styles from './NetworthPlot.module.css'; // Import the CSS file
import LoadingAnimation from './LoadingAnimation';
import { apiDomain } from './Domain'; // Import the apiDomain variable


// Register the modules
Plotly.register([Pie]);

// Define config as a memoized constant
const config = {
    responsive: true,
    displayModeBar: false,
    staticPlot: false,
};



const NetworthPlotly = () => {
    const [networthgraphData, setNetworthgraphData] = useState(null);
    const [networthgraphlayout, setNetworthgraphlayout] = useState(null);
    const [networthreturngraphData, setNetworthreturngraphData] = useState(null);
    const [networthreturngraphlayout, setNetworthreturngraphlayout] = useState(null);
    
    const [loading, setLoading] = useState(true);
    const [dataFetched, setDataFetched] = useState(false);

    // useCallback for the fetchData function
    const fetchData = useCallback(async () => {
        try {
            if (!dataFetched) {
            setLoading(true);
            //console.log('fetch');
            const apiUrl = `${apiDomain}/api/fetchnetworthplot`;

            const response = await axios.get(apiUrl, {
                method: 'GET',
                mode: 'cors',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-App-Identifier': 'wishh/1.0',
                },
                responseType: 'json', // Set responseType to 'json'
            });
                
                const networthgraphdata = JSON.parse(response.data[0]);
                const returngraphdata = JSON.parse(response.data[1]);
                
                // Access the properties of each object
                //console.log(networthgraphdata)
                setNetworthgraphData(networthgraphdata.data);
                
                setNetworthgraphlayout(networthgraphdata.layout);
                setNetworthreturngraphData(returngraphdata.data);

                setNetworthreturngraphlayout(returngraphdata.layout);
                
                setLoading(false);
            setDataFetched(true);
            //console.log('API Data:', response.data);
            }
        } catch (error) {
            console.error('Error fetching graph data:', error);
            setLoading(false);
        }
    }, [dataFetched]);

    // useEffect to fetch data on mount
    useEffect(() => {
       //console.log('fetch effect');
        fetchData();
    }, [fetchData]);
    function plotInContainer(data, containerId, graphLayout) {
        Plotly.newPlot(containerId, data, graphLayout, config);
    }
    // useEffect to plot data when graphData changes
    useEffect(() => {
        //console.log('plot effect');
        if (networthgraphData) {
            // Plot within the custom container after fetching data
            plotInContainer(networthgraphData, 'plot1', networthgraphlayout);
        }
        if (networthreturngraphData) {
            // Plot within the custom container after fetching data
            plotInContainer(networthreturngraphData, 'plot2', networthreturngraphlayout);
        }
        
    }, [networthgraphData, networthreturngraphData, networthgraphlayout, networthreturngraphlayout]);

    const PlotComponent = ({ id, loading, customStyle }) => (
        <div id={id} className={`${customStyle}`}>
            {loading && <LoadingAnimation />}
            {/* Content for the plot */}
        </div>
    );


    return (
        
        <div id="graphContainer" className={styles.GraphContainer}>
            {/* Plot 1 */}
            <div id="plot1" className={styles.Plot}>
                <PlotComponent id="plot1" loading={loading} customStyle={styles.Plot1Style} />
            </div>
            {/* Plot 2 */}
            <div id="plot2" className={styles.Plot}>
                <PlotComponent id="plot2" loading={loading} customStyle={styles.Plot2Style} />
            </div>
            
        </div>

           
    );
};

export default NetworthPlotly;
