
import { apiDomain } from '../comp/Domain'; // Import the apiDomain variable
import axios from 'axios';





const init = async (setUserData) => {
    // Your login logic here
    
  const apiUrl = `${apiDomain}/api/init`;
        try {
            // Send a request to the backend to logout
                const response = await axios.get(apiUrl, {
            method: 'GET',
            mode: 'cors',
            withCredentials: true,
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'X-App-Identifier': 'wishh/1.0',
              // Other headers as needed
            },
          });
           const fetch_data = response.data
        if (fetch_data.error) {
        
            
        alert("Please try again!");
         // Optionally, you might want to navigate to the home route
                window.location.href = '/';
        
        }
        else {
            
            // Check the response and update user data accordingly
            if (fetch_data.data) {
                setUserData({
                    code: response.data.data.user_code, // or an appropriate default value
                    profilePic: null, // or an appropriate default value
                    name: null, // or an appropriate default value
                    loggedin: response.data.data.loggedin,
                });

               }
            } 
        } catch (error) {
            // Handle network or other errors
            console.error('Error during init:', error);
        }

  };


const login = async (userData,setUserData,navigate) => {
    // Your login logic here
    

  const apiUrl = `${apiDomain}/api/isloggedin`;
        try {
            // Send a request to the backend to logout
                const response = await axios.get(apiUrl, {
            method: 'GET',
            mode: 'cors',
            withCredentials: true,
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'X-App-Identifier': 'wishh/1.0',
              // Other headers as needed
            },
          });
           const fetch_data = response.data
        if (fetch_data.error) {
        
            
        alert("Please try again!");
         // Optionally, you might want to navigate to the home route
                navigate('/'); 
        
        }
        else {
            
            // Check the response and update user data accordingly
            if (fetch_data.data) {
                setUserData({
                    code: response.data.data.user_code, // or an appropriate default value
                    profilePic: response.data.data.profilepic, // or an appropriate default value
                    name: response.data.data.name, // or an appropriate default value
                    loggedin: response.data.data.loggedin,
                });
           
              } 
            } 
        } catch (error) {
            // Handle network or other errors
            console.error('Error during login:', error);
        }

  };

  const logout = async (navigate) => {
     

       const apiUrl = `${apiDomain}/api/logout`;
        try {
            // Send a request to the backend to logout
                const response = await axios.get(apiUrl, {
            method: 'GET',
            mode: 'cors',
            withCredentials: true,
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'X-App-Identifier': 'wishh/1.0',
              // Other headers as needed
            },
          });

            // Check the response and update user data accordingly
            if (response.data.code === 200) {
                console.log('Logout :', response.data.code);
                
                // Clear all data from local storage
                localStorage.clear();
                navigate('/'); 
                window.location.reload();

            } else {
                // Handle logout failure, maybe show an error message
                console.error('Logout failed:', response.data.message);

                // Clear all data from local storage
                localStorage.clear();
                 navigate('/'); 
                window.location.reload();
            }
        } catch (error) {
            // Handle network or other errors
            console.error('Error during logout:', error);
        }
    };

export { login, logout, init };