

import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import axios from 'axios';
import { useNavigate  } from 'react-router-dom';
import styles from './Profilebasic.module.css'; // Import the CSS module
import Button from './Button'; // Import the Button component
import { apiDomain } from './Domain'; // Import the apiDomain variable
import { useAuth } from '../AuthContext';
import {checkUserData} from '../utils/session';
import { logout } from '../utils/utils';

const Profilebasic = () => {
    
    const { userData } = useAuth(); // Use the userData state from AuthProvider
     const navigate = useNavigate();

    const fadeIn = useSpring({
    opacity: window.scrollY > 200 ? 1 : 0, // Adjust the scroll threshold as needed
    from: { opacity: 0 },
  });

  const [notification, setNotification] = useState('');
    const [loading, setLoading] = useState(false);
   
  const handleButtonClick = async () => {

      // Reset previous notifications
    setNotification('');
     setLoading(true);
    // Validate that all fields are filled
      if (!gender || !dob || !relationship || (relationship === 'married' && (!spousegender || !spousedob || !kids))) {
      setNotification('All fields are required');
      // Automatically clear the notification after 3 seconds (3000 milliseconds)
      setTimeout(() => {
        setNotification('');
        setLoading(false);
      },  1000);
      return;
    }

 


  // Gather data from state variables
  const data = {
    dob,
   relationship,
    gender,
    spousedob,
    spousegender,
    kids
    
  };

      // Send data to Flask backend using Axios
      try {
      const apiUrl = `${apiDomain}/api/submit-profilebasic-data`;
      const response = await axios.post(apiUrl, data, {
      method: 'POST',
      mode: 'cors',
      withCredentials: true, // Include credentials (cookies) with the request
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-App-Identifier': 'wishh/1.0',
        // Other headers as needed
      },
    });
    console.log()
    const fetch_data = response.data;
    if (fetch_data.error) {

        alert("Please try again!");
       navigate('/')
    }

   else {
      // Handle the response from the backend
        console.log('API Response Message:', response.data.message);
       alert(response.data.message)
     }   
    }
    catch(error) {
      console.error('Error posting data:', error.message);
      // Handle errors or provide user-friendly messages as needed
    }
    finally {
        // Reset loading state after the request is complete
        setLoading(false);
      }
};




useEffect(() => {
  const handleScroll = () => {
    // Update the fade-in effect based on the scroll position
    fadeIn.opacity.set(window.scrollY > 0 ? 1 : 0);
  };

  // Attach the event listener when the component mounts
  window.addEventListener('scroll', handleScroll);

  // Check user data and navigate if needed
  if (checkUserData(userData)) {
    // session expired go to home page
    alert('Session Expired! Login Again.');
    navigate('/');
  }

  // Clean up the event listener when the component unmounts
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };

}, [fadeIn.opacity, userData, navigate]); 

    
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make a GET request to fetch data from the Flask backend
        const apiUrl = `${apiDomain}/api/fetch-profilebasic-data`;
        const response = await axios.get(apiUrl, {
          
          method: 'POST',
          mode: 'cors',
          withCredentials: true, // Include credentials (cookies) with the request
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-App-Identifier': 'wishh/1.0',
            // Other headers as needed
        }
        });
        const fetch_data = response.data
        if (fetch_data.error) {
        
            
        alert("Please try again!");

            await logout(navigate);
        
        }
        else {
            // Check if data is available in the response
        const responseData = response.data.data;
        if (responseData) {
          // Update state variables with fetched data
         setGender(responseData.gender || '');
        setDob(responseData.dob || '');
        setRelationship(responseData.relationship || '');
       setSpousegender(responseData.spousegender || '');
       setSpousedob(responseData.spousedob || '');
        setKids(responseData.kids || '');
          
        }
          // Optionally, you can also trigger any necessary actions or updates here
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
        // Handle errors or provide user-friendly messages as needed
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, [navigate]); // The empty dependency array ensures that this effect runs only once on mount


    
    const [dob, setDob] = useState('');
    const [spousedob, setSpousedob] = useState('');
    const [relationship, setRelationship] = useState('');
    const [gender, setGender] = useState('');
    const [spousegender, setSpousegender] = useState('');
    const [kids, setKids] = useState('');
  
          
    const [activeTab, setActiveTab] = useState('self'); // Default to 'self' tab
    

  return (
     <animated.section className={styles['profile-section']} >
      <div className={styles.container}>
      <h2 className={styles.heading}>Household Profile</h2>
      <div className={styles.customp}>
                    Enter details as a couple, if married
              </div>
              {/* Tab Navigation */}
    <div className={styles.tabContainer}>
      <div
        className={`${styles.tab} ${activeTab === 'self' && styles.activeTab}`}
        onClick={() => setActiveTab('self')}
      >
        Self
      </div>
      <div
        className={`${styles.tab} ${activeTab === 'spouse' && styles.activeTab}`}
        onClick={() => setActiveTab('spouse')}
      >
        Spouse
      </div>
      {/* Add more tabs as needed for other roles */}
    </div>
    <div className={styles.cardContainer}>
    <div className={`${styles.card} ${activeTab === 'self' && styles.activeCard}`}>
 <div className={styles.inputContainer}>
        <div className={styles.labelRow}>
          <label htmlFor="dob" className={styles.label}>DOB:</label>
                      {/*<span className={styles.value}>{dob}</span>*/}
        
        <input
        id="dob"
            name="dob"
                      type="date"
                      value={dob}
          onChange={(e) => setDob(e.target.value)}
                      className={styles.dateInput}
        />
          </div>
      </div>
        <div className={styles.inputContainer}>
            <label htmlFor="gender" className={styles.singlelabel}>
                Gender:
                
            </label>
                  <select
                       id="gender"
                       name="gender"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                className={styles.inputSelect}
                
            >

                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
            </select>
        </div>
       
              <div className={styles.inputContainer}>
                  <label  htmlFor="relationship" className={styles.singlelabel}>
                      Marital Status:

                  </label>
                  <select
                    id="relationship"
                    name="relationship"
                      value={relationship}
                      onChange={(e) => setRelationship(e.target.value)}
                      className={styles.inputSelect}

                  >

                      <option value="">Select Status</option>
                      <option value="single">Single</option>
                      <option value="married">Married</option>
                  </select>
              </div>

      </div>
      <div className={`${styles.card} ${activeTab === 'spouse' && styles.activeCard}`}>
            <div className={styles.inputContainer}>
            <div className={styles.labelRow}>
          <label htmlFor="spousedob" className={styles.label}>DOB:</label>
                      {/*<span className={styles.value}>{dob}</span>*/}
        
        <input
        id="spousedob"
            name="spousedob"
                      type="date"
                      value={spousedob}
          onChange={(e) => setSpousedob(e.target.value)}
                      className={styles.dateInput}
        />
          </div>
      </div>
      <div className={styles.inputContainer}>
            <label htmlFor="spousegender" className={styles.singlelabel}>
                Gender:
                
            </label>
                  <select
                       id="spousegender"
                       name="spousegender"
                      value={spousegender}
                      onChange={(e) => setSpousegender(e.target.value)}
                className={styles.inputSelect}
                
            >

                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
            </select>
        </div>
        <div className={styles.inputContainer}>
            <label htmlFor="kids" className={styles.singlelabel}>
                Kids:
                
            </label>
                  <select
                       id="kids"
                       name="kids"
                      value={kids}
                      onChange={(e) => setKids(e.target.value)}
                className={styles.inputSelect}
                
            >

                      <option value="">Have kids?</option>
                      <option value="0">No</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
            </select>
        </div>
      </div>
     </div>
      <div className={styles.connectButton}>
          <Button label='Submit' onClick={handleButtonClick} disabled={loading}></Button>

              </div>
              {notification && <p style={{ color: 'yellow',fontSize: '10px'  }}>{notification}</p>}
    </div>
          
     </animated.section>
  );
};

export default Profilebasic;
