// LoadingAnimation.js
import React from 'react';
import styles from './Loading.module.css'; // Import the CSS file for styling

const LoadingAnimation = () => {
    return (
        <div className={styles.loadingContainer}>
            <div className={styles.loadingText}>Your wishh is my command...</div>
            <div className={styles.loadingSpinner}></div>
        </div>
    );
};

export default LoadingAnimation;
